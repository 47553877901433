import axios from 'axios';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';

// https://taekwonworld.pe.kr/ 서버 URL, 위치: .env 파일
const BASE_URL = process.env.REACT_APP_API_ROOT;
// const BASE_URL = "http://ec2-3-34-115-229.ap-northeast-2.compute.amazonaws.com:8080/"

// const accessToken = sessionStorage.getItem('access_token')
// const refreshToken = sessionStorage.getItem('refresh_token')
const authApi = axios.create({
    baseURL: BASE_URL,
    timeout: 50000,
    headers: {
        'Content-Type': 'application/json',
    },
});


authApi.interceptors.request.use(
    (config) => {
        const accessToken = sessionStorage.getItem('access_token')
        const token = accessToken;
        config.headers = {
        Authorization: token ? `Bearer ${token}` : '',
        ...config.headers,
    };
    // console.log('header: ', token)
    return config;
},
    (error) => {
        return Promise.reject(error);
    },
    );
    authApi.interceptors.response.use(
        (response) => {
            return response;
        },
    async (error) => {
        const accessToken = sessionStorage.getItem('access_token')
        const refreshToken = sessionStorage.getItem('refresh_token')
        const originalConfig = error.config;
        const decodedAccess = jwtDecode(accessToken);
        const isExpired = dayjs.unix(decodedAccess.exp).diff(dayjs()) < 1;
        try {
        if (isExpired) {
            const res = await authApi.get('user/refresh', {
              headers: {
                ...originalConfig,
                refresh: refreshToken,
              }
            });
            sessionStorage.setItem('access_token', res.data.data.accessToken);
            sessionStorage.setItem('refresh_token', res.data.data.refreshToken);
            window.location.reload();
            return authApi(originalConfig);
        }
        } catch (err) {
            return Promise.reject(err);
        }
        return Promise.reject(error);
    },
);


export default authApi;
