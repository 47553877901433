export const toLowerObj = (obj) => {
  const newObj = {}
  for (let key in obj) {
    newObj[key?.toLowerCase()] = obj?.[key]
  }
  return newObj
}

export const toLowerArray = (array) => {
  const newArray = []
  for (let i = 0; i < array?.length; i++) {
    newArray.push(toLowerObj(array?.[i]))
  }
  return newArray
}
