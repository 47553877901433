import authApi from "../authApi"
import { FormDataConfig } from "../config"
import { addCountryCode } from "../contryCode"

// 관장 정보 조회 (수정)
export const getAccountInfo = async () => {
  const res = await authApi.get('kwanjang/kwanjangreadall')
  return res.data
}

// 관장 조회
export const getKwanjangInfo = async (body) => {
  const res = await authApi.post('kwanjang/kwanjangread', body)
  return res.data
}

// 태권도 경력 수정 (수정 user_id 사용  --> career, award, license, ...)
export const updateTeacherInfo = async (body) => {
  const res = await authApi.post('kwanjang/teacherupdate', body)
  return res.data
}

// 프로필 이미지 변경
export const updateTeacherImg = async (body) => {
  const res = await authApi.post('kwanjang/profile_update', body, FormDataConfig)
  return res.data
}

// 기본 정보 변경
export const updateTeacherAccountInfo = async (body) => {
  const res = await authApi.post('kwanjang/teacher_info_update', body, FormDataConfig)
  return res.data
}

// 관장 / 사범 생성 (수정  --> 내 관장 / 사범 정보 생성)
export const createTeacherInfo = async (body) => {
  const res = await authApi.post('kwanjang/teachercreate', body, FormDataConfig)
  return res.data
}



// export const updateKwanjang = async (body) => {
//   const bodyy = addCountryCode(body);
//   const res = await authApi.post('kwanjang/kwanjangupdate/', bodyy, FormDataConfig)
//   return res
// }










