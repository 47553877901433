import React from 'react'
import { styled } from '@mui/system'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import SecondaryButton from '../../ATOM/BUTTON/SecondaryButton'
import { FormattedMessage } from 'react-intl'

const BoxStyle = styled(Box)(({ theme, width, height, bgcolor }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: width,
  height: height,
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 30px',
  backgroundColor: bgcolor,
  borderRadius: '5px',
  boxShadow: 24,
  overflow: 'auto',
}))

const AlertBox = ({
  open,
  onClose,
  onConfirm,
  confirmText,
  isLoading,
  children,
  width,
  height,
  bgColor,
  className,
  type,
}) => {
  const locale = localStorage.getItem('locale')

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        disableAutoFocus
      >
        <BoxStyle
          width={width || 400}
          height={locale !== 'ko' ? 320 : height || 280}
          bgcolor={bgColor || '#ffffff'}
          className={`${className ?? ''}`}
        >
          {children}
          <div className='absolute bottom-[38px] right-[30px] flex mt-[20px] justify-end gap-[5px]'>
            <SecondaryButton
              onClick={onClose}
              minWidth='80px'
              fontSize='14px'
            >
              <FormattedMessage id='취소' />
            </SecondaryButton>
            <SecondaryButton
              onClick={(e) => {
                onConfirm(e)
                onClose()
              }}
              loading={isLoading}
              variant='contained'
              minWidth='80px'
              fontSize='14px'
            >
              {confirmText}
            </SecondaryButton>
          </div>
        </BoxStyle>
      </Modal>
    </>
  )
}

export default AlertBox
