import React from 'react'
import { FormattedMessage } from 'react-intl'
import PrimaryButton from '../../../../../UI/ATOM/BUTTON/PrimaryButton'
import Typography from '../../../../../UI/ATOM/TEXT/HOME/Typography'

const PgSignButton = () => {
  return (
    <PrimaryButton
      width='340px'
      height='38px'
      variant='contained'
      type='submit'
    >
      <Typography className='font-bold text-[16px]'>
        <FormattedMessage id='PG 가입하기' />
      </Typography>
    </PrimaryButton>
  )
}

export default PgSignButton
