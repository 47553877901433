export const theme = {
  palette: {
    type: 'light',
    primary: {
      main: '#2F5DF2',
    },
    secondary: {
      main: '#5E81F4',
    },
    text: {
      main: '#333333',
      hover: '#3045FF',
    },
    input: {
      main: '#000000',
      focus: '#5E81F4',
      hover: '#B5B5B5',
      disabled: '#B5B5B5',
      success: '#037E00',
      error: '#C11C1C',
      label: '#00196A',
    },
    textarea: {
      main: '#000000',
      bg: '#FAFAFA',
      border: '#AAAAAA',
    },
    progress: {
      main: '#2F5DF2',
      sub: '#B5B5B5',
    },
    icon: {
      main: '#ffffff',
    },
    success: {
      main: '#037E00',
    },
    error: {
      main: '#C11C1C',
    },
    info: {
      main: '#00196A',
    },
    denger: {
      main: '#C11C1C',
    },
    absend: {
      main: '#FF0000',
    },
    late: {
      main: '#FF7A00',
    },
    button: {
      secondary: {
        main: '#5E81F4',
      },
      neutral1: {
        main: '#555555',
      },
      neutral2: {
        main: '#DDDDDD',
      },
      neutral3: {
        main: '#AAAAAA',
      },
    },
    calendar: {
      test: {
        main: '#F45E5E',
        bg: '#FCCFCF',
      },
      secondary: {
        main: '#5E81F4',
        bg: '#CFD9FC',
      },
      training: {
        main: '#04B500',
        bg: '#B4E9B3',
      },
      regular: {
        main: '#FF971E',
        bg: '#FFE0BB',
      },
      birth: {
        main: '#FF95E1',
        bg: '#F4D5EC',
      },
      besides: {
        main: '#2ED9FF',
        bg: '#B6E9F4',
      },
    },
    grid: {
      border: '#E7E7E7',
      exist: {
        text: '#555555',
        bg: '#ffffff',
        border: {
          wrap: '#555555',
          rows: '#E7E7E7',
        },
        hover: {
          bg: '#E7E7E7',
        },
      },
    },
    table: {
      text: '#333333',
      border: '#AAAAAA',
    },
    background: {
      default: '#F2F2F2',
      secondary: '#FAFAFA',
      table: '#F5F7F8',
      hover: '#F5F7F8',
      active: '#F5F7F8',
    },
  },
  button: {
    common: {
      radius: '5px',
      lgRadius: '36px',
      border: {
        main: '#AAAAAA',
      },
      shadow: {
        none: 'none',
        main: '0px 4px 10px rgba(0, 0, 0, 0.25)',
      },
      disabled: {
        bg: '#F2F2F2',
        color: '#555555',
        border: '#DDDDDD',
      },
      paddingSize: {
        xs: '5px 10px',
        sm: '5px 15px',
        md: '5px 30px',
        lg: '10px 60px',
        xl: '5px 100px',
        long: '10px 150px',
      },
      width: {
        xs: '50px',
        sm: '70px',
        md: '140px',
        lg: '250px',
      },
      height: {
        sm: '30px',
        md: '40px',
        lg: '50px',
      },
    },
    primary: {
      none: 'none',
      default: 'transparent',
      background: '#5E81F4',
      border: '#5E81F4',
      color: '#FFFFFF',
      padding: {
        sm: '5px 15px',
        md: '5px 20px',
        lg: '10px 10px',
      },
      disabled: {
        background: '#F2F2F2',
        border: '#AAAAAA',
        color: '#AAAAAA',
      },
      hover: {
        background: '#6e8ffd',
        border: '#5E81F4',
        color: '#FFFFFF',
      },
    },
    secondary: {
      none: 'none',
      default: 'transparent',
      background: '#FFFFFF',
      border: '#DDDDDD',
      color: '#5E81F4',
      disabled: {
        background: '#F2F2F2',
        border: 'none',
        color: '#AAAAAA',
      },
      hover: {
        background: '#fafafa',
        border: '#DDDDDD',
        color: '#FFFFFF',
      },
    },
    tertiary: {
      none: 'none',
      default: '#5E81F4',
      background: 'transparent',
      border: '#5E81F4',
      color: '#5E81F4',
      disabled: {
        background: 'transparent',
        border: '#AAAAAA',
        color: '#AAAAAA',
      },
      hover: {
        background: '#5E81F4',
        border: 'transparent',
        color: '#FFFFFF',
      },
    },
    create: {
      none: 'none',
      default: 'transparent',
      background: '#F2F2F2',
      border: '#DDDDDD',
      color: '#555555',
      disabled: {
        background: '#FFFFFF',
        border: '#AAAAAA',
        color: '#AAAAAA',
      },
      hover: {
        background: '#555555',
        border: 'transparent',
        color: '#FFFFFF',
      },
    },
    black: {
      none: 'none',
      default: 'transparent',
      background: '#555555',
      border: 'transparent',
      color: '#FFFFFF',
      padding: {
        sm: '5px 15px',
        md: '5px 20px',
        lg: '10px 10px',
      },
      disabled: {
        background: '#F2F2F2',
        border: '#AAAAAA',
        color: '#AAAAAA',
      },
      hover: {
        background: '#777777',
        border: '#555555',
        color: '#555555',
      },
    },
    white: {
      none: 'none',
      default: 'transparent',
      background: '#FFFFFF',
      border: 'none',
      borderMD: '#DDDDDD',
      color: '#555555',
      disabled: {
        background: '#FFFFFF',
        border: '#AAAAAA',
        color: '#AAAAAA',
      },
      hover: {
        background: '#F2F2F2',
        border: '#555555',
        color: '#FFFFFF',
      },
    },
    modal: {
      none: 'none',
      default: 'transparent',
      background: '#FFFFFF',
      border: '#888888',
      color: '#888888',
      disabled: {
        background: '#FFFFFF',
        border: '#AAAAAA',
        color: '#AAAAAA',
      },
      hover: {
        background: '#F2F2F2',
        border: '#555555',
        color: '#FFFFFF',
      },
    },
    box: {
      defalut: '#F2F2F2',
      hover: '#DDDDDD',
      active: '#DDDDDD',
    },
  },
  select: {
    basic: {
      border: '#DDDDDD',
      radius: '5px',
      disabled: {
        background: '#F2F2F2',
        color: '#555555',
        border: '#DDDDDD',
      },
      hover: {
        border: 'B5B5B5',
      },
      focus: {
        label: '#000000',
        border: '#000000',
      },
    },
    country: {
      border: '#DDDDDD',
      radius: '5px',
      disabled: {
        background: '#F2F2F2',
        color: '#555555',
        border: '#DDDDDD',
      },
      hover: {
        border: 'B5B5B5',
      },
      focus: {
        label: '#000000',
        border: '#000000',
      },
    },
  },
  font: {
    size: {
      xs: 10,
      sm: 14,
      m: 16,
      md: 18,
      lg: 20,
      xl: 32,
    },
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700,
      superBold: 900,
    },
  },
  typography: {
    fontFamily: [
      'Pretendard',
      'Noto Sans KR',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      '"Fira Sans"',
      '"Droid Sans"',
      '"Helvetica Neue"',
      'sans-serif',
      'Arial',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 32,
      fontWeight: 700,
    },
    h2: {
      fontSize: 24,
      fontWeight: 700,
    },
    h3: {
      fontSize: 20,
      fontWeight: 700,
    },
    h4: {
      fontSize: 18,
      fontWeight: 400,
    },
    h5: {
      fontSize: 16,
      fontWeight: 400,
    },
    h6: {
      fontSize: 14,
      fontWeight: 400,
    },
  },
}

export default theme
