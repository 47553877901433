import moment from 'moment'
import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'
import { getCurrDateString } from '../../UTILS/basic/getDate'

const { persistAtom } = recoilPersist({
  key: 'dojangInfo',
  storage: sessionStorage,
})

const dojangInfoAtom = atom({
  key: 'dojangInfo',
  default: '',
  effects_UNSTABLE: [persistAtom],
})

export const dojangIdSelector = selector({
  key: 'dojangIdSelector',
  get: ({ get }) => {
    const dojangInfo = get(dojangInfoAtom)
    return dojangInfo?.dojang_id ?? ''
  },
})

export const dojangNameSelector = selector({
  key: 'dojangNameSelector',
  get: ({ get }) => {
    const dojangInfo = get(dojangInfoAtom)
    return dojangInfo?.dojang_name ?? ''
  },
})

export const dojangPaymentSelector = selector({
  key: 'dojangPaymentSelector',
  get: ({ get }) => {
    const dojangInfo = get(dojangInfoAtom)

    const currDate = moment(getCurrDateString())
    const expDate = moment(dojangInfo?.exp_ymd ?? '')
    const dayDiff = currDate?.diff(expDate, 'days') ?? ''

    const injuryTime = 3

    // 1. 결제
    if (dayDiff + injuryTime < 0) return { state: 1 }
    // 2. 이용기한 3일전
    if (0 <= dayDiff + injuryTime && dayDiff <= 0) return { state: 2, dayDiff }
    // 3. 유예기한 3일
    if (0 < dayDiff && dayDiff <= injuryTime) return { state: 0, dayDiff }
    // 4. 유예기한 3일초과
    if (dayDiff < injuryTime) return { state: 0, dayDiff }
    return false
  },
})

export default dojangInfoAtom
