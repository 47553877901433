import React from 'react'
import Logo from '../../../asset/Images/Logo/logo_footer.png'
import Wrap from '../../../UI/LAYOUT/HOME/WRAP/Wrap'
import Typography from '../../../UI/ATOM/TEXT/HOME/Typography'
import { useResize } from '../../../hooks/window/useResize'
import { FormattedMessage } from 'react-intl'
import FlexWrap from '../../../UI/LAYOUT/WRAP/FlexWrap'
import FlexColWrap from '../../../UI/LAYOUT/WRAP/FlexColWrap'
import { useMediaQuery } from '@mui/material'

const FooterForInvitation = () => {
  // const mobile = useResize({ md: true, sm: true })
  const mobile = useMediaQuery(`(max-width: 340px)`)

  const containerStyle = useResize({
    lg: 'bg-[#E8ECFA] text-[#191919] w-full',
    md: 'bg-[#E8ECFA] text-[#191919] w-full px-[40px]',
    sm: 'bg-[#E8ECFA] text-[#191919] w-full px-[20px]',
  })

  const logoStyle = useResize({
    lg: 'mb-[20px]',
    md: 'text-center',
    sm: 'text-center',
  })

  const logoSize = useResize({
    sm: 'max-w-[280px] w-[200px] min-w-[160px]',
  })

  const flexColWrapStyle = useResize({
    md: 'items-start',
    sm: 'items-start',
  })

  const labelStyle = useResize({
    lg: { bold: 'bold', leading: '26px' },
    md: { bold: 'bold', leading: '26px' },
    sm: { variant: 'p2', bold: 'bold', leading: '20px' },
  })

  const contentStyle = useResize({
    lg: { leading: '26px' },
    md: { leading: '26px' },
    sm: { variant: 'p2', leading: '23px' },
  })

  return (
    <div className={containerStyle}>
      <Wrap>
        <FlexColWrap
          gap='5px'
          className={flexColWrapStyle}
        >
          <div className={logoStyle}>
            <img
              src={Logo}
              className={logoSize}
              alt=''
            />
          </div>
          <FlexWrap className={`gap-[10px] ${mobile ? 'items-start' : 'items-center'}`}>
            <Typography {...labelStyle}>
              <FormattedMessage id='사업자 등록번호' />
            </Typography>
            {/* <Typography {...contentStyle}>894-88-02212 / 830-88-02877</Typography> */}
            <div className={`${mobile ? 'flex flex-col' : 'flex gap-[5px]'}`}>
              <Typography {...contentStyle}>894-88-02212 /</Typography>
              <Typography {...contentStyle}>830-88-02877</Typography>
            </div>
          </FlexWrap>
          <FlexColWrap gap='10px'>
            <FlexWrap className='gap-3'>
              <Typography {...labelStyle}>
                <a
                  href='/policy/privacy'
                  target='_blank'
                >
                  <FormattedMessage id='개인정보처리방침' />
                </a>
              </Typography>
            </FlexWrap>
            <Typography {...contentStyle}>COPYRIGHT ⓒ 2023 AIVill, SPORTSENT INC. ALL RIGHTS RESERVED.</Typography>
          </FlexColWrap>
        </FlexColWrap>
      </Wrap>
    </div>
  )
}

export default FooterForInvitation
