import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { useLocation } from 'react-router-dom'
import { MenuItem } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import useNavSearch from '../../../../../hooks/navigation/useNavSearch'

const Accordion = styled((props) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  padding: '0px',
  '&:before': {
    display: 'none',
  },
  '& .Mui-expanded': {
    fontWeight: 'bold',
    borderRadius: '5px',
  },
}))

const AccordionSummary = styled(({ icon, ...props }) => (
  <MuiAccordionSummary
    expandIcon={icon && <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', transform: 'rotate(90deg)' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  padding: '0px 20px',
  width: '100%',
  minHeight: '52px',
  '& .MuiAccordionSummary-content': {
    margin: '0px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '7px'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0px',
  paddingTop: '5px',
  paddingBottom: '5px',
  '& .MuiMenuItem-root': {
    fontSize: '15px',
    '&:hover': {
      borderRadius: '5px',
    },
    '&:active': {
      backgroundColor: '#D2DAF5',
      borderRadius: '5px',
    },
    '&:focus': {
      backgroundColor: '#D2DAF5',
      borderRadius: '5px',
      color: '#000000',
    },
  },
}))

const SideBarMenu = ({ menu, otherMenu }) => {
  const currPath = useLocation().pathname
  const navigate = useNavSearch()
  const [expanded, setExpanded] = useState('')
  const [activeSubMenu, setActiveSubMenu] = useState('')

  useEffect(() => {
    if (['/home', 'dashboard'].includes(currPath)) {
      setExpanded('')
    } else {
      const filtered = menu?.filter((item) => {
        if (item?.sub) {
          let isCurrMenu = false
          for (let v of item.sub) {
            if (currPath.includes(v.path)) isCurrMenu = true
          }
          return isCurrMenu
        }
      })
      setExpanded(filtered?.[0]?.name ?? '')
    }
  }, [currPath, menu])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleSubMenuClick = (path, name, params) => {
    setActiveSubMenu(name)
    navigate(path, params)
  }

  return (
    <div className='side_menu_scroll'>
      {React.Children.toArray(
        menu?.map((item) => (
          <>
            {item?.path && (
              <div
                onClick={() => {
                  setExpanded('')
                  navigate(item?.path, item?.params)
                }}
                disabled={item?.path === '/'}
                className={currPath.includes(item?.path) ? 'bg-[var(--color-background-blue-300)]' : ''}
                style={{
                  backgroundColor: '#FFFFFF',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  height: '52px',
                  padding: '0px 20px',
                  gap: '7px',
                  cursor: 'pointer',
                }}
              >
                <img
                  src={item?.src}
                  className='h-[20px]'
                  alt=''
                />

                <Typography className='w-[130px] truncate'>
                  <FormattedMessage id={item?.title} />
                </Typography>

              </div>
            )}
            {item?.sub && (
              <Accordion
                expanded={expanded === item?.name}
                onChange={handleChange(item?.name)}
              >
                <AccordionSummary icon>
                  <img
                    src={item?.src}
                    className='h-[20px]'
                    alt=''
                  />
                  <Typography className='w-[130px] truncate'>
                    <FormattedMessage id={item?.title} />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {item?.sub &&
                    React.Children.toArray(
                      item?.sub.map((tem) => (
                        <MenuItem
                          className={
                            activeSubMenu === tem?.title
                              ? 'bg-[var(--color-background-blue-300)] rounded-none w-full truncate px-10'
                              : 'w-full truncate rounded-none px-10'
                          }
                          onClick={() => handleSubMenuClick(tem?.path, tem?.title, tem?.params)}
                          disabled={!tem?.path}
                        >
                          <FormattedMessage id={tem?.title} />
                          {tem?.new && (
                            <span className='pl-3 text-[#ff963a] text-[12px] text-end'>new</span>
                          )}
                        </MenuItem>
                      )),
                    )}
                </AccordionDetails>
              </Accordion>
            )}
          </>
        )),
      )}

      <div className='w-[233px] h-[170px]'></div>

      <div className='fixed left-0 bottom-0 w-[233px] h-auto bg-[#fff] border border-solid border-[var(--color-border-grey-400)] border-l-0 border-b-0'>
        {React.Children.toArray(
          otherMenu?.map((item) => (
            <MenuItem
              className={
                currPath.includes(item.path)
                  ? 'bg-[var(--color-background-blue-300)] rounded-none py-3 w-full truncate font-bold px-[20px] h-[52px]'
                  : 'py-3 w-full truncate rounded-none px-[20px] h-[52px]'
              }
              onClick={() => {
                if (item?.newTab) {
                  window.open(item?.path)
                } else {
                  navigate(item?.path, item?.params)
                }
              }}
            >
              <img
                src={item?.iconUrl}
                className='h-[20px] mr-1'
                alt=''
              />
              {!item?.chip && <FormattedMessage id={item?.title} />}
              {item?.chip && (
                <span className='flex items-center justify-between w-full'>
                  <FormattedMessage id={item?.title} />
                  {item?.chip}
                </span>
              )}
            </MenuItem>
          )),
        )}
      </div>
    </div>
  )
}

export default SideBarMenu
