import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { createTheme, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import themes from './themes'
// import NewThemes from "./themes/NewThemes";

import App from './App'
import reportWebVitals from './reportWebVitals'

const theme = createTheme(themes)
// const newTheme = createTheme(NewThemes);
const root = ReactDOM.createRoot(document.getElementById('root'))
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      suspense: true,
      useErrorBoundary: true,
    },
  },
})

root.render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </RecoilRoot>
  </QueryClientProvider>,
)

// reportWebVitals()
