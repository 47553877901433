import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import FlexWrap from '../../../../../UI/LAYOUT/WRAP/FlexWrap'
import CheckIcon from '@mui/icons-material/Check'
import FlexColWrap from '../../../../../UI/LAYOUT/WRAP/FlexColWrap'
import Typography from '../../../../../UI/ATOM/TEXT/HOME/Typography'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import PrimaryButton from '../../../../../UI/ATOM/BUTTON/PrimaryButton'
import BillFooter from '../../CHECK/Components/BillFooter'
import { useNavigate } from 'react-router-dom'

// s3URL
const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL

const Success = ({ orderNo, timestamp, amount, productName, dojangName }) => {
  const navigate = useNavigate()

  useEffect(() => {
    window.history.pushState(null, '', window.location.href)

    const preventGoBack = (event) => {
      event.preventDefault()
      window.history.pushState(null, '', window.location.href)
    }

    window.addEventListener('popstate', preventGoBack)

    return () => {
      window.removeEventListener('popstate', preventGoBack)
    }
  }, [navigate])

  return (
    <FlexColWrap>
      <FlexWrap className='w-full justify-center pt-[15px] pb-[12px] text-[#2AB22F]'>
        <CheckIcon />
        <FormattedMessage id='결제완료' />
      </FlexWrap>
      <FlexColWrap>
        <div className='flex flex-col gap-2'>
          <Typography className={'self-start text-sm '}>
            <FormattedMessage id='영수증' />
          </Typography>
          <div className='flex flex-col bg-white gap-3 px-[15px] py-[10px] pb-2 text-sm'>
            <FlexWrap className='justify-between items-center'>
              <Typography className={'text-[#555555] text-sm py-[8px]'}>
                <FormattedMessage id='주문번호' />
              </Typography>
              <span className='text-black'>{orderNo}</span>
            </FlexWrap>
            <FlexWrap className='justify-between items-center'>
              <Typography className={'text-[#555555] text-sm py-[8px]'}>
                <FormattedMessage id='결제일시' />
              </Typography>
              <span className='text-black'>{timestamp}</span>
            </FlexWrap>
            <FlexWrap className='justify-between items-center'>
              <Typography className={'text-[#555555] text-sm py-[8px]'}>
                <FormattedMessage id='도장' />
              </Typography>
              <span className='text-black'>{dojangName}</span>
            </FlexWrap>
            <FlexWrap className='justify-between items-center'>
              <Typography className={'text-[#555555] text-sm py-[8px]'}>
                <FormattedMessage id='항목' />
              </Typography>
              <span className='text-black'>{productName}</span>
            </FlexWrap>
            <FlexWrap className='justify-between items-center'>
              <Typography className={'text-[#555555] text-sm py-[8px]'}>
                <FormattedMessage id='금액' />
              </Typography>
              <span className='text-black'>{amount} 원</span>
            </FlexWrap>
          </div>
          <Typography className={'flex self-start w-full items-center text-sm text-[#757575] mb-[25px]'}>
            <InfoOutlinedIcon sx={{ fontSize: 16, width: 16, height: 16, marginRight: 0.5 }} />
            <FormattedMessage id='태권월드 앱에서 결제내역을 확인할 수 있습니다.' />
          </Typography>
        </div>
        <div className='flex flex-col bg-white gap-3 px-[10px] py-[20px]'>
          <div className='flex flex-col items-center justify-center'>
            <Typography className={'text-sm text-[#646464] font-bold'}>
              <FormattedMessage id='태권월드 앱을 아직 사용하지 않고 계신가요?' />
            </Typography>
            <Typography className={'text-sm text-[#646464] font-bold'}>
              <FormattedMessage id='관장님께 초대 요청을 보내고,' />
            </Typography>
            <Typography className={'text-sm text-[#646464] font-bold'}>
              <FormattedMessage id='태권월드 앱에서 다양한 기능들을 만나보세요.' />
            </Typography>
          </div>
          <div className='h-full px-[20px]'>
            <img
              className='h-[245px]'
              src={`${s3URL}/invite/app_icon_menu.jpg`}
              alt='icons'
            />
            {/* <PrimaryButton
              width='100%'
              height='32px'
              variant='contained'
              type='submit'
              sx={{
                borderRadius: '5px',
                fontWeight: 'bold',
                marginBottom: '15px',
              }}
            >
              <FormattedMessage id='초대요청 보내기' />
            </PrimaryButton> */}
          </div>
        </div>
        <BillFooter />
      </FlexColWrap>
    </FlexColWrap>
  )
}

export default Success
