import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useModal } from '../../../hooks/basic/useModal'
import SecondaryButton from '../../ATOM/BUTTON/SecondaryButton'
import AlertBox from '../../MOLECULES/ALERT/AlertBox'
import AlertText from '../../MOLECULES/TEXT/ALERT/AlertText'
import DeleteText from '../../MOLECULES/TEXT/DELETE/DeleteText'
import FlexWrap from '../WRAP/FlexWrap'

const Header = ({ temporary, onlyLogo, children }) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL

  // FormattedMessage
  const logoutText = <FormattedMessage id='로그아웃' />
  const logoutAlertTitle = <FormattedMessage id='로그아웃 하시겠습니까?' />
  const logoutAlertText = <FormattedMessage id='로그아웃 후 홈화면으로 돌아갑니다.' />

  // hook
  const [openLog, openFnLog, closeFnLog] = useModal()

  //  s3 url
  const setImg = {
    logo: `${s3URL}/asset/images/logo/TKW_LOGO.png`,
    option: `${s3URL}/asset/images/icon/header/option.png`,
    bell: `${s3URL}/asset/images/icon/header/notice.png`,
  }

  return (
    <>
      <div className='header-container'>
        <div className='header-logo'>
          <a href='/'>
            <img
              src={setImg.logo}
              alt='logo'
              className='w-[210px]'
            />
          </a>
        </div>

        {!onlyLogo && (
          <div className='header-right'>
            <FlexWrap className={'items-center gap-[30px]'}>
              <DeleteText
                color='var(--color-primary)'
                text={<FormattedMessage id='태권월드 가이드' />}
                onClick={() => window.open('/guide', '_blank')}
              />
              {/* { !temporary &&
                  <img src={setImg.option} onClick={() => navigate('/mypage')} alt={intl.formatMessage({id:"설정"})} className="w-[20px] h-[20px]" />
                } */}
              {/* <img src={setImg.bell} alt={intl.formatMessage({ id: '알림' })} className="mr-[20px]" /> */}
              <SecondaryButton
                onClick={openFnLog}
                width='90px'
              >
                {logoutText}
              </SecondaryButton>
            </FlexWrap>
          </div>
        )}
      </div>

      <AlertBox
        open={openLog}
        onClose={closeFnLog}
        onConfirm={() => navigate('/logout')}
        confirmText={logoutText}
      >
        <AlertText title>{logoutAlertTitle}</AlertText>
        <AlertText>{logoutAlertText}</AlertText>
      </AlertBox>
    </>
  )
}

export default Header
