import { IconButton, Popover } from '@mui/material'
import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import ProfileImage from '../../../../../MOLECULES/PROFILE/ProfileImage'
import Typography from '../../../../../ATOM/TEXT/Typography'
import { getFullname } from '../../../../../../UTILS/render/getFullname'
import { FormattedMessage } from 'react-intl'
import { getRoleText } from '../../../../../../UTILS/render/getRoleText'
import FlexWrap from '../../../../WRAP/FlexWrap'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getAccountInfo } from '../../../../../../api/Kwanjang/kwanjang'
import { getAccountInfoSabum } from '../../../../../../api/Sabum/sabum'
import userInfoAtom from '../../../../../../recoil/atom/login/userInfoAtom'
import { useRecoilValue } from 'recoil'
import { toLowerArray, toLowerObj } from '../../../../../../UTILS/render/toLower'
import UserInfoFetcher from '../../../../../../PAGES/USERDOJANG/MYPAGE/ACCOUNT/Components/UserInfo/Components/Fetcher/UserInfoFetcher'
import UserInfoContext from '../../../../../../PAGES/USERDOJANG/MYPAGE/ACCOUNT/Components/UserInfo/Components/Fetcher/context'

const popoverStyle = {
  marginTop: '10px',
  '& .MuiPopover-paper': {
    border: '1px solid var(--color-border-grey-400)',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.25)',
  },
}

const SideBarLogo = () => {
  // popover
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const openPop = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const closePop = () => {
    setAnchorEl(null)
  }

  // hooks
  const nav = useNavigate()
  const { id, user_role } = useRecoilValue(userInfoAtom)
  const role = user_role === 'KW' ? 'kwanjang' : 'sabum'

  // query
  // const { data } = useQuery(
  //   ['getAccountInfo'],
  //   () => {
  //     if (user_role === 'KW') {
  //       return getAccountInfo()
  //     }
  //     if (user_role === 'SA') {
  //       return getAccountInfoSabum()
  //     }
  //   },
  //   {
  //     enabled: !!id,
  //     select: (res) => {
  //       let temp = {
  //         info: toLowerObj(res?.[`${role}_info`]),
  //         award_info: toLowerArray(res?.[`${role}_award_info`]),
  //         career_info: toLowerArray(res?.[`${role}_career_info`]),
  //         license_info: toLowerArray(res?.[`${role}_license_info`]),
  //       }
  //       return temp
  //     },
  //   },
  // )

  return (
    <div className='flex justify-between items-center px-2'>
      <IconButton
        className='text-[#222] rounded-none'
        onClick={openPop}
      >
        <MenuIcon />
      </IconButton>
      <Popover
        open={open}
        onClose={closePop}
        anchorReference='anchorPosition'
        anchorPosition={{ top: 38, left: 10 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={popoverStyle}
      >
        <UserInfoFetcher>
          <UserInfoContext.Consumer>
            {({ data }) => (
              <div className='w-[233px]'>
                <div className='side-profile-img'>
                  <ProfileImage
                    size='58px'
                    urlKey={data?.profile_img}
                  />
                  <div className='flex flex-col gap-1'>
                    <div className='text-center'>
                      <Typography className='text-[15px]'>{getFullname(data?.last_name, data?.first_name)}</Typography>
                      <Typography className='font-normal text-[15px]'>
                        {<FormattedMessage id={getRoleText(user_role)} />}
                      </Typography>
                    </div>
                    <FlexWrap className='gap-2'>
                      <Typography
                        className='cursor-pointer text-[#777777] text-[13px] font-normal'
                        onClick={() => {
                          nav('/mypage/account')
                          closePop()
                        }}
                      >
                        <FormattedMessage id='내 정보' />
                      </Typography>
                      <Typography
                        className='cursor-pointer text-[#777777] text-[13px] font-normal'
                        onClick={() => nav('/logout')}
                      >
                        <FormattedMessage id='로그아웃' />
                      </Typography>
                    </FlexWrap>
                  </div>
                </div>
              </div>
            )}
          </UserInfoContext.Consumer>
        </UserInfoFetcher>
      </Popover>
      <div>
        <a
          href='/'
          className='flex justify-center'
        >
          <img
            src={image.logo}
            alt='TAEKWON WORLD'
            className='w-full h-[50px] py-2 pr-2 pl-1 object-contain'
          />
        </a>
      </div>
    </div>
  )
}

export default SideBarLogo

const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL
const image = {
  logo: `${s3URL}/asset/images/logo/TKW_LOGO.png`,
}
