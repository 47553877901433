import { useMediaQuery } from "@mui/material";

export const useResize = ({lg, md, sm}) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const isTablet = useMediaQuery("(max-width: 1023px) and (min-width: 768px)");
  const isMobile = useMediaQuery(`(max-width: 767px)`);

 if (isDesktop) {
  return lg;
 }  else if (isTablet) {
  return md;
 } else if (isMobile) {
  return sm;
 } else {
  return "";
 }
}