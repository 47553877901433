import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
  key: 'userInfo',
  storage: sessionStorage,
})

const userInfoAtom = atom({
  key: 'userInfo',
  default: '',
  effects_UNSTABLE: [persistAtom],
})

export const isKoreaSelector = selector({
  key: 'isKorea',
  get: ({ get }) => {
    const userInfo = get(userInfoAtom)
    return userInfo?.country_code === '0082'
  },
})

export const countryCodeSelector = selector({
  key: 'countryCode',
  get: ({ get }) => {
    const userInfo = get(userInfoAtom)
    return userInfo?.country_code
  },
})

export const userRoleSelector = selector({
  key: 'userRole',
  get: ({ get }) => {
    const userInfo = get(userInfoAtom)
    return userInfo?.user_role
  },
})

export default userInfoAtom
