import * as React from 'react'
import { Modal, Box } from '@mui/material'
import CircularProgressForMovePage from './Progress/CircularProgressForMovePage'

const LoadingModal = () => {
  return (
    <Modal
      className='flex justify-center items-center'
      open={true}
      disableScrollLock
    >
      <Box sx={{ width: '100vw', height: '100vh', position: 'relative' }}>
        <CircularProgressForMovePage />
      </Box>
    </Modal>
  )
}

export default LoadingModal
