import React from 'react'
import Typography from '../../../../../UI/ATOM/TEXT/HOME/Typography'
import { FormattedMessage } from 'react-intl'

const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL

const PGInfoTable = () => {
  return (
    <div className='px-[50px] pb-[30px] bg-white'>
      <div>
        <Typography className='text-[#000000] text-[18px] pb-[20px]'>
          <FormattedMessage id='- PG사 정보' />
        </Typography>
      </div>
      <div>
        <table className='w-[60%] border border-solid border-collapse'>
          <tbody>
            <tr>
              <td
                rowSpan='2'
                className='h-[152px] px-[24px] border border-solid border-[#C6C6C6] text-center'
              >
                PG사
              </td>
              <td className='w-[120px] border border-solid border-[#C6C6C6] text-center text-[16px]'>일반결제</td>
              <td className='w-[233px] py-[24px] px-[10px] border border-solid border-[#C6C6C6] text-center'>
                <img
                  src={`${s3URL}/pay/payment_intro_logo_kis.jpg`}
                  alt='KIS정보통신'
                  className='w-[178px] h-[41px]'
                />
              </td>
              <td className='w-[160px] px-[15px] border border-solid border-[#C6C6C6] text-[16px]'>KIS정보통신(주)</td>
              <td className='border border-solid border-[#C6C6C6] text-[16px]'>
                <a
                  href='https://www.kisvan.co.kr'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <span className='pl-[15px]'>https://www.kisvan.co.kr</span>
                </a>
              </td>
            </tr>
            <tr>
              <td className='w-[120px] border border-solid border-[#C6C6C6] text-center text-[16px]'>
                보이는결제
                <br />
                <span className='text-[13px]'>(NFC결제)</span>
              </td>
              <td className='w-[233px] py-[24px] px-[10px] border border-solid border-[#C6C6C6] text-center'>
                <img
                  src={`${s3URL}/pay/payment_intro_logo_cg.jpg`}
                  alt='CallGate'
                  className='w-[153px] h-[29px]'
                />
              </td>
              <td className='w-[160px] px-[15px] border border-solid border-[#C6C6C6] text-[16px]'>(주)콜게이트</td>
              <td className='border border-solid border-[#C6C6C6] text-[16px]'>
                <a
                  href='https://www.callgate.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <span className='pl-[15px]'>https://www.callgate.com</span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PGInfoTable
