import React from 'react'
import Typography from '../../../ATOM/TEXT/Typography'
import PrimaryButton from '../../../ATOM/BUTTON/PrimaryButton'
import { FormattedMessage } from 'react-intl'

const ApiErrorFallback = ({ error, resetErrorBoundary }) => {
  console.log(error)
  return (
    <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
      {/* <div className='w-full h-full flex justify-center items-center'> */}
      <div className='flex flex-col justify-center items-center gap-3'>
        <span className='flex flex-col justify-center items-center'>
          <Typography
            className='text-[#282828] mb-[2px]'
            variant='h5'
          >
            <FormattedMessage id='잠시후 다시 시도해주세요.' />
          </Typography>
          <Typography className='text-[#929292] text-[12px]'>
            <FormattedMessage id='데이터 요청에 실패하였습니다.' />
          </Typography>
        </span>
        <PrimaryButton onClick={() => resetErrorBoundary()}>
          <FormattedMessage id='재시도' />
        </PrimaryButton>
      </div>
    </div>
  )
}

export default ApiErrorFallback
