import React from 'react'
import { styled } from '@mui/system'
import { Box } from '@mui/material'
import BackButton from '../../MOLECULES/BUTTON/BACK/BackButton'
import DashboardBackground from '../../MOLECULES/BACKGROUND/DashboardBackground'
import Tooltip from '../../ATOM/TOOLTIP/Tooltip'
import { useLocation } from 'react-router-dom'
import { ContainerSizePathList } from '../UTILS/ContainerSizePathList'
import GuideTextTooltip from '../../MOLECULES/TOOLTIP/GuideTextTooltip/GuideTextTooltip'
import FlexWrap from '../WRAP/FlexWrap'

const Title = styled(Box)(({ theme }) => ({
  height: '55px',
  fontWeight: '700',
  fontSize: '22px',
  lineHeight: 1.6,
  paddingBottom: '10px',
  marginBottom: '20px',
  borderBottom: '1px solid #BDBDBD',
  color: '#000000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidht: '1630px',
  width: '1630px',
}))

const Container = ({ title, onClickArrow, btn, dashboard, tooltip, onClickGuide, children, ...props }) => {
  const { pathname } = useLocation()

  if (ContainerSizePathList.includes(pathname)) {
    return (
      <div className='main-container bg-[#F5F5F5] h-[100vh] m-auto'>
        <div className='main-contents'>
          <div className=''>
            {title && (
              <Title>
                <span>
                  {onClickArrow && (
                    <BackButton
                      onClick={onClickArrow}
                      sx={{ paddingBottom: '5px' }}
                    />
                  )}
                  {title}
                  {tooltip && <Tooltip content={tooltip} />}
                </span>
                {btn && <>{btn}</>}
              </Title>
            )}
          </div>
          {children}
        </div>
      </div>
    )
  }

  return (
    <div className='main-container ml-[232px] bg-[#F5F5F5]'>
      {dashboard && <DashboardBackground {...props} />}
      <div className='main-contents'>
        <div className=''>
          {title && (
            <Title>
              <FlexWrap className='items-center gap-2'>
                <span>
                  {onClickArrow && (
                    <BackButton
                      onClick={onClickArrow}
                      sx={{ paddingBottom: '5px' }}
                    />
                  )}
                  {title}
                  {tooltip && <Tooltip content={tooltip} />}
                </span>
                {onClickGuide && <GuideTextTooltip onClick={onClickGuide} />}
              </FlexWrap>
              {btn && <>{btn}</>}
            </Title>
          )}
        </div>
        {children}
      </div>
    </div>
  )
}

export default Container
