import authApi from "../authApi"
import { FormDataConfig } from "../config"
import { addCountryCode } from "../contryCode"

// 사범 조회 (수정 get -> post)
export const getSabumInfo = async (body) => {
  const res = await authApi.post('sabum/sabumread', body);
  return res.data
}

// 도장, 반 별 사범 조회 (수정 get -> post)
export const getSabumList = async (body) => {
  const res = await authApi.post('sabum/sabumreadbyclass', body)
  return res.data
}

// 사범 / 관장 상세 조회 (수정 get -> post)
export const getTeacherSpecInfo = async (body) => {
  const res = await authApi.post('sabum/teacherreaddetail', body)
  return res.data
}

// 관장이 사범 퇴소
// export const outSabumToClass = async (dojangId, sabumId) => {
//   const res = await authApi.delete(`sabum/sabumdojangdisconnect/${dojangId}/${sabumId}`)
//   return res
// }

// 반에 사범 / 관장 추가 (수정)
export const addTeacher = async (body) => {
  const res = await authApi.post('sabum/teacherclassconnect', body)
  return res.data
}

// 도장에서 사범 / 관장 삭제 (관장) 
export const deleteTeacher = async (body) => {
  const res = await authApi.post('kwanjang/teacherdelete', body)
  return res.data;
}

// 사범 정보 (수정)
export const getAccountInfoSabum = async () => {
  const res = await authApi.get('sabum/sabumreadall')
  return res.data
}

// 사범 퇴소(사범) & 도장 삭제(관장) (수정)
export const resignSabum = async (body) => {
  // const fullBody = addCountryCode(body);
  const res = await authApi.post('dojang/dojangdelete', body)
  return res.data;
}

export const createSabumInfo = async (body) => {
  const res = await authApi.post('sabum/sabumcreate', body, FormDataConfig)
  return res
}

export const updateSabumInfo = async (sabumId, body) => {
  const res = await authApi.post(`sabum/sabumupdate/${sabumId}`, body, FormDataConfig)
  return res
}

