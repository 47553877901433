import React from 'react'
import { circularProgressClasses } from '@mui/material/CircularProgress'
import { CircularProgress as MUICircularProgress } from '@mui/material'

const CircularProgressForMovePage = () => {
  return (
    <>
      <MUICircularProgress
        variant='determinate'
        sx={{
          color: (theme) => theme.palette.progress.main,
          position: 'absolute',
          top: 'calc(50% - 20px)',
          left: 'calc(50% - 20px)',
        }}
        size={40}
        thickness={7}
        value={100}
      />
      <MUICircularProgress
        variant='indeterminate'
        disableShrink
        sx={{
          color: (theme) => theme.palette.progress.sub,
          animationDuration: '600ms',
          position: 'absolute',
          top: 'calc(50% - 20px)',
          left: 'calc(50% - 20px)',
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={7}
      />
    </>
  )
}

export default CircularProgressForMovePage
