import React from 'react'
import Typography from '../../../../../UI/ATOM/TEXT/HOME/Typography'
import { FormattedMessage } from 'react-intl'
import CheckIcon from '@mui/icons-material/Check'

const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL

const itemsList = [
  {
    url: `${s3URL}/pay/payment_intro_1_1.jpg`,
    title: '간편한 청구서 발송',
    dec: '수련생 선택 후 바로 발송할 수 있고,<br />한번에 여러명도 발송 가능합니다.',
  },
  {
    url: `${s3URL}/pay/payment_intro_1_2.jpg`,
    title: '편리한 모바일결제',
    dec: '학부모님께 모바일결제 제공으로,<br />연체율↓ 관리효율↑',
  },
  {
    url: `${s3URL}/pay/payment_intro_1_3.jpg`,
    title: '납부 관리와 연동',
    dec: '납부관리에서 청구서결제, 현장결제를<br />한번에 관리할 수 있습니다.',
  },
]

const PGIntroContent = () => {
  return (
    <div className='bg-white'>
      <div className='flex flex-col justify-center text-center pt-[50px] pb-[120px] px-[50px]'>
        <div className='flex justify-center items-center gap-[25px] pb-[10px]'>
          <div className='flex items-center text-[#14AE5C] font-bold text-[20px] '>
            <CheckIcon className='text-[34px]' />
            <FormattedMessage id='가입비 무료' />
          </div>
          <div className='flex items-center text-[#14AE5C] font-bold text-[20px]'>
            <CheckIcon className='text-[34px]' />
            <FormattedMessage id='심사비 무료' />
          </div>
          <div className='flex items-center text-[#14AE5C] font-bold text-[20px]'>
            <CheckIcon className='text-[34px]' />
            <FormattedMessage id='청구서발송비 무료' />
          </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-3 px-[30px] py-[10px]'>
          {itemsList.map((item, index) => (
            <div
              key={index}
              className='text-center w-full h-[450px] flex flex-col gap-[30px] justify-end items-center'
            >
              <div className='w-full'>
                <img
                  src={item.url}
                  alt={item.title}
                  className='object-contain w-full max-h-[224px]'
                />
              </div>
              <div>
                <Typography className='font-bold text-[30px] text-[#000000] mb-2'>{item.title}</Typography>
                <Typography
                  component='div'
                  className='text-[#000000] text-[20px]'
                  dangerouslySetInnerHTML={{ __html: item.dec }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PGIntroContent
