import { Button, Input, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const ErrorFallback = ({ error, resetErrorBounday }) => {
  const nav = useNavigate()
  // const { status } = error.response
  // const { title } = getErrorMessage(status)

  // console.log(title)

  const handleClickHome = () => {
    nav('/')
    window.location.reload()
  }
  const isSmall = useMediaQuery(`(max-width: 1080px)`)
  console.log(error)

  return (
    <>
      {!isSmall && (
        <div className='bg-[#000] h-[100vh] flex justify-center items-center'>
          <div className='w-[1100px] h-auto bg-[#fff] rounded-[15px] px-[10px] pt-[30px] pb-[40px]'>
            <div className='flex flex-col items-center gap-[30px]'>
              <img
                src=''
                className='w-[250px]'
                alt=''
              />
              <div className='flex gap-3 h-[350px]'>
                <Typography className='px-3 w-[500px] text-[20px] text-[#555555] leading-[36px] font-semibold text-left border-r-[#aaa] border-r-[1px]'>
                  네트워크 오류가 발생하였습니다. <br />
                  불편을 드려 죄송합니다. <br />
                  <br />
                  네트워크 연결을 확인해주시고 다시 확인해주세요. <br />
                  <br />
                  문의사항은 아래 연락처로 연락부탁드립니다.
                  <br />
                  스포츠이앤티 조재윤팀장
                  <br />
                  010-8569-0422
                </Typography>
              </div>
              <div className='flex justify-center items-center gap-[10px]'>
                <Button
                  variant='outlined'
                  size='small'
                  onClick={handleClickHome}
                >
                  HOME
                </Button>
                <Button
                  variant='outlined'
                  size='small'
                  onClick={() => window.location.reload()}
                >
                  RETRY
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isSmall && (
        <div className='bg-[#000] h-[1000px] flex justify-center items-center'>
          <div className='w-[350px] h-auto bg-[#fff] rounded-[15px] px-[10px] pt-[30px] pb-[40px]'>
            <div className='flex flex-col items-center gap-[30px]'>
              <img
                src=''
                className='w-[250px]'
                alt=''
              />
              <div className='flex flex-col gap-3'>
                <Typography className='px-2 text-[20px] text-[#555555] leading-[36px] font-semibold text-left'>
                  네트워크 오류가 발생하였습니다. <br />
                  불편을 드려 죄송합니다. <br />
                  <br />
                  네트워크 연결을 확인해주시고 다시 확인해주세요. <br />
                  <br />
                  문의사항은 아래 연락처로 연락부탁드립니다.
                  <br />
                  스포츠이앤티 조재윤팀장
                  <br />
                  010-8569-0422
                </Typography>
              </div>
              <div className='flex justify-center items-center gap-[10px]'>
                <Button
                  variant='outlined'
                  size='small'
                  onClick={handleClickHome}
                >
                  HOME
                </Button>
                <Button
                  variant='outlined'
                  size='small'
                  onClick={() => window.location.reload()}
                >
                  RETRY
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ErrorFallback
