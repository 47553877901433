import React, { useEffect, useState } from 'react'
import FlexColWrap from '../../../../../UI/LAYOUT/WRAP/FlexColWrap'
import Typography from '../../../../../UI/ATOM/TEXT/HOME/Typography'
import { FormattedMessage } from 'react-intl'
import PrimaryButton from '../../../../../UI/ATOM/BUTTON/PrimaryButton'
import FlexWrap from '../../../../../UI/LAYOUT/WRAP/FlexWrap'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { failPayment, getBillTokenCheck, successPayment } from '../../../../../api/Payment/payment'
import BillFooter from './BillFooter'
import { CircularProgress } from '@mui/material'

const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL

export const BillInquiry = ({ data, logo }) => {
  const [loading, setLoading] = useState(false)
  const formattedDate = moment(data.EXP_YMD, 'YYYYMMDD').format('YYYY년 MM월 DD일')
  const { bill_id } = useParams()
  const navigate = useNavigate()

  const { mutate: getTokenCheck } = useMutation(getBillTokenCheck, {
    onSuccess: async (result) => {
      const { paymentDetails, authToken } = result

      const VIEWPAY_DOMAIN = 'https://vl.boinenpay.com'
      // const VIEWPAY_DOMAIN = 'https://stgvl.boinenpay.com'

      const res = await window.VIEWPAY.openViewPay(VIEWPAY_DOMAIN, paymentDetails, 'Bearer ' + authToken)
      if (res?.code === '0000') {
        // setLoading(false)
        console.log(res)
        // } else {
        //   throw ''
      }
    },
    onError: (error) => {
      console.error('결제 데이터를 가져오는 중 오류가 발생했습니다.', error)
      alert(
        '요청에 실패하였습니다. \n\n같은 현상이 반복될 경우 아래 연락처로 문의주시길 바랍니다. \n감사합니다. \n\nCS팀: 010-7615-6646',
      )
      setLoading(false)
    },
  })

  const { mutate: successMutation } = useMutation(successPayment, {
    onSuccess: (data) => {
      const { ORDER_NUMBER, TIMESTAMP, AMOUNT, MAIN_PRODUCT_NAME, DOJANG_NAME } = data.data

      navigate(
        `/bill/result?paymentStatus=done&orderNo=${ORDER_NUMBER}&timestamp=${TIMESTAMP}&amount=${AMOUNT}&productName=${MAIN_PRODUCT_NAME}&dojangName=${DOJANG_NAME}`,
      )
    },
    onError: (error) => {
      console.error('결제 성공 후 처리 중 오류 발생:', error)
      navigate(`/bill/result?paymentStatus=failure`)
    },
  })

  const { mutate: failMutation } = useMutation((data) => failPayment(data))

  const handlePaymentClick = () => {
    if (!loading) {
      setLoading(true)
      getTokenCheck(bill_id)
    }
  }

  useEffect(() => {
    const handleMessage = async (event) => {
      const resultData = event.data
      // console.log('event: ', event)
      // console.log('resultData: ', resultData)

      // if (!resultData || !resultData.event) {
      //   console.error('결제 데이터가 유효하지 않음') //////////
      //   // alert(
      //   //   '요청에 실패하였습니다. \n\n같은 현상이 반복될 경우 아래 연락처로 문의주시길 바랍니다. \n감사합니다. \n\nCS팀: 010-7615-6646',
      //   // )
      // }

      // console.log(resultData)
      if (resultData?.event) {
        // 결제 성공 여부 직접 확인
        if (resultData?.event?.resultCode === '0000' && resultData?.event?.eventCode === 'done') {
          // 결제 성공 처리
          successMutation(resultData)
        }
        // 결제 취소 또는 실패 처리
        if (resultData?.event?.eventCode === 'cancel' || resultData?.event?.resultCode !== '0000') {
          failMutation(resultData, {
            onSuccess: () => {
              navigate(
                `/bill/result?paymentStatus=failure&resultCode=${resultData.event.resultCode}&message=${resultData.event.message}&bill_id=${bill_id}`,
              )
            },
            onError: (error) => {
              console.error('결제 실패 후 처리 중 오류 발생1:', error)
              // navigate(`/bill/result?paymentStatus=failure`)
            },
          })
        }
        // 그 외 에러 처리
        if (resultData?.event?.eventCode === 'error') {
          failMutation(resultData, {
            onSuccess: () => {
              navigate(
                `/bill/result?paymentStatus=failure&resultCode=${resultData.event.resultCode}&message=${resultData.event.message}&bill_id=${bill_id}`,
              )
            },
            onError: (error) => {
              console.error('결제 실패 후 처리 중 오류 발생2:', error)
              navigate(`/bill/result?paymentStatus=failure&bill_id=${bill_id}`)
            },
          })
        }
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [successMutation, failMutation])

  return (
    <FlexColWrap>
      <div className='flex flex-col gap-2'>
        <Typography className={'self-start text-sm mt-4 text-[#000000]'}>
          <FormattedMessage id='청구서 납부' />
        </Typography>
        <div className='flex flex-col bg-white px-[15px] pt-[10px] pb-2 text-sm text-[#555555]'>
          <FlexWrap
            style={{ borderBottom: '1px dashed #D0D6DB', paddingBottom: '16px', paddingTop: '16px' }}
            className='justify-between'
          >
            <FormattedMessage id='태권도장' />
            <div className='flex'>
              <img
                className='w-[23px] h-[22px] mr-1'
                src={`${s3URL}/${logo}`}
                alt='logo'
              />
              <span className='text-[#000000]'>{data.DOJANG_NAME}</span>
            </div>
          </FlexWrap>
          <FlexWrap
            style={{ borderBottom: '1px dashed #D0D6DB', paddingBottom: '16px', paddingTop: '16px' }}
            className='justify-between items-center'
          >
            <FormattedMessage id='주소' />
            <div className='flex flex-col items-end'>
              <span className='text-[#000000]'>{data.ROAD_ADDRESS}</span>
              <span className='text-[#000000]'>{data.ADDRESS_DETAIL}</span>
            </div>
          </FlexWrap>
          <FlexWrap
            style={{ borderBottom: '1px dashed #D0D6DB', paddingBottom: '16px', paddingTop: '16px' }}
            className='justify-between'
          >
            <FormattedMessage id='전화번호' />
            <span className='text-[#000000]'>{data.PHONE_NUMBER}</span>
          </FlexWrap>
          <FlexWrap
            style={{ borderBottom: '1px dashed #D0D6DB', paddingBottom: '16px', paddingTop: '16px' }}
            className='justify-between'
          >
            <FormattedMessage id='항목' />
            <span className='text-[#2E5CFF] text-[20px] font-bold'>{data.MAIN_PRODUCT_NAME}</span>
          </FlexWrap>
          <FlexWrap
            style={{ borderBottom: '1px dashed #D0D6DB', paddingBottom: '16px', paddingTop: '16px' }}
            className='justify-between'
          >
            <FormattedMessage id='금액' />
            <span className='text-[#2E5CFF] text-[20px] font-bold'>
              {parseInt(data.AMOUNT).toLocaleString('ko-KR')} 원
            </span>
          </FlexWrap>
        </div>
        <div className='w-full bg-white text-start text-sm px-3 py-4 min-h-[112px]'>
          <FlexWrap className={`mb-3`}>
            <Typography className={`text-sm text-[#555555]`}>
              <FormattedMessage id='태권도장 안내메세지' />
            </Typography>
          </FlexWrap>
          <Typography className='whitespace-pre-wrap flex items-center'>{data?.INFO_MESSAGE}</Typography>
        </div>
        <Typography className={'flex self-start justify-center items-center text-sm text-[#757575] mb-2'}>
          <InfoOutlinedIcon sx={{ fontSize: 16, width: 16, height: 16, marginRight: 0.5 }} />
          <FormattedMessage
            id='이 청구서는 {formattedDate}에 만료됩니다.'
            values={{ formattedDate }}
          />
        </Typography>
      </div>
      <PrimaryButton
        width='auto'
        height='52px'
        variant='contained'
        type='submit'
        onClick={handlePaymentClick}
        sx={{
          marginBottom: '20px',
          borderRadius: '10px',
          fontWeight: 'bold',
        }}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <span className='text-[21px]'>
            <FormattedMessage id='결제하기' />
          </span>
        )}
      </PrimaryButton>
      <BillFooter />
    </FlexColWrap>
  )
}

// status_code = 4 [결제 만료], status_code = 5 [삭제]
export const BillStatusMessage = ({ status_code }) => {
  const getMessage = () => {
    switch (status_code) {
      case 2:
        return {
          title: '결제 완료',
          description: '이미 결제된 청구서입니다.',
        }
      case 3:
        return {
          title: '결제 취소',
          description: '결제 취소된 청구서입니다.',
        }
      case 4:
        return {
          title: '만료된 청구서입니다.',
          description: '청구서가 만료되었습니다.',
        }
      case 5:
        return {
          title: '삭제된 청구서입니다.',
          description: '청구서가 삭제되었습니다.',
        }
      default:
        return {
          title: '존재하지 않는 청구서입니다.',
          description: '청구서 상태를 확인할 수 없습니다.',
        }
    }
  }

  const { title, description } = getMessage()

  return (
    <div className='h-full'>
      <FlexColWrap>
        <Typography className={'mt-16'}>
          <span className='text-sm font-bold text-red-500'>
            <FormattedMessage id={title} />
          </span>
        </Typography>
      </FlexColWrap>
      <div className='mt-6 text-[#757575] pb-[250px]'>
        <Typography>
          <span className='text-sm'>
            <FormattedMessage id={description} />
          </span>
        </Typography>
        <Typography>
          <span className='text-sm'>
            <FormattedMessage id='태권도장 관장님께 문의해주세요.' />
          </span>
        </Typography>
      </div>
      <div>
        <BillFooter />
      </div>
    </div>
  )
}
