import React from 'react'
import { Outlet } from 'react-router-dom'
import GuideHeader from '../HEADER/GuideHeader'

const LayoutGuide = () => {
  return (
    <div className='bg-[#fff] min-w-[1903px] w-full'>
      <GuideHeader />
      <Outlet />
    </div>
  )
}

export default LayoutGuide
