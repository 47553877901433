import React from 'react'

const ContainerNoPadding = ({ children }) => {
  return (
    <div className='main-contents-noPadding ml-[232px] bg-[#F5F5F5]'>
      <div className='main-contents-noPadding'>{children}</div>
    </div>
  )
}

export default ContainerNoPadding
