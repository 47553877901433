import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import LoadingModal from '../../MOLECULES/LOADING/LoadingModal'
import SideBarAssociation from './MENU/SideBarAssociation'

const LayoutAssociation = ({ children }) => {
  return (
    <>
      <div className='bg-body'>
        <div className='main-wrap'>
          <SideBarAssociation />
          <Suspense fallback={<LoadingModal />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </>
  )
}

export default LayoutAssociation
