import React from 'react'
import Header from '../HEADER/Header'
import { Outlet } from 'react-router-dom'

const ContainerOnlyTopbar = () => {
  return (
    <div className='relative'>
      <Header onlyLogo/>
      <div className='pt-[70px]' />
      <Outlet />
    </div>
  )
}

export default ContainerOnlyTopbar