import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import ApiErrorFallback from '../ErrorFallback/ApiErrorFallback'

const ApiErrorBoundary = ({ children }) => {
  const { reset } = useQueryErrorResetBoundary()

  return (
    <ErrorBoundary
      fallbackRender={ApiErrorFallback}
      onReset={reset}
    >
      {children}
    </ErrorBoundary>
  )
}

export default ApiErrorBoundary
