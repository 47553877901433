import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { getLinkedInfoforUpdate } from '../../../../../../../../api/user'
import { toLowerObj } from '../../../../../../../../UTILS/render/toLower'
import { hyphenReg } from '../../../../../../../../UTILS/lib/Regex'
import { useRecoilValue } from 'recoil'
import userInfoAtom from '../../../../../../../../recoil/atom/login/userInfoAtom'
import UserInfoContext from './context'

const UserInfoFetcher = ({ children }) => {
  const isLogin = sessionStorage.getItem('access_token')

  const userInfo = useRecoilValue(userInfoAtom)

  const { data, refetch } = useQuery(['getLinkedInfo'], () => getLinkedInfoforUpdate(), {
    enabled: !!isLogin,
    select: (res) => {
      let temp = toLowerObj(res)
      let result = {
        username: userInfo?.username,
        profile_img: temp?.profile_img,
        first_name: temp?.first_name,
        last_name: temp?.last_name,
        email: temp?.email,
        phone_number: hyphenReg(temp?.phone_number),
        dob: temp?.dob,
        sex_code: temp?.sex_code,
        img: temp?.img ? temp?.img : '',
      }
      // console.log(result)
      return result
    },
  })

  return <UserInfoContext.Provider value={{ data }}>{children}</UserInfoContext.Provider>
}

export default UserInfoFetcher
