import React from 'react'
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip as MUITooltip } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';


const TooltipStyled = styled(({ className, ...props }) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 1000,
    fontSize: '14px',
    border: '1px solid #929292',
    padding: '15px 20px',
  },
}));

const contentRender = (content) => {
  return (
    <div className='flex flex-col gap-2'>
      {
        React.Children.toArray(
          content?.map(item => (
            <div className='flex items-center'>
              <CheckOutlinedIcon />
              <span className=''>
                {item}
              </span>
            </div>
          ))
        )
      }
    </div>
  )
}

const Tooltip = ({ placement, content, labelTooltip }) => {
  return (
    <TooltipStyled
      placement={placement ?? 'bottom-start'}
      title={contentRender(content)}
    >
      <span className='ml-1'>
        <InfoIcon className={`align-bottom ${labelTooltip ? 'pt-[7px] pb-[3px] text-[#c0c0c0]' : 'pb-2 text-[#929292]'} text-[30px]`} />
      </span>
    </TooltipStyled>
  )
}

export default Tooltip