import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { checkDojangInfo, createPGState } from '../../../../../api/Payment/payment'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import dojangIdAtom from '../../../../../recoil/atom/dojangIdAtom'
import FlexWrap from '../../../../../UI/LAYOUT/WRAP/FlexWrap'
import InputControlled from '../../../../../UI/MOLECULES/INPUT/Controlled/InputControlled'
import AlignLabel from '../../../../../UI/ATOM/LABEL/AlignLabel'
import PgSignButton from './PgSignButton'
import ModalAddress from './ModalAddress'
import PrimaryButton from '../../../../../UI/ATOM/BUTTON/PrimaryButton'

const PgSignForm = () => {
  const { control, handleSubmit, setValue, getValues, setError } = useForm()
  const dojang_id = useRecoilValue(dojangIdAtom)
  const { data } = useQuery(['dojangInfo', dojang_id], () => checkDojangInfo(dojang_id), {
    enabled: !!dojang_id,
  })

  const [isModalOpen, setIsModalOpen] = useState(false) // 우편번호 모달 상태
  const [businessType, setBusinessType] = useState('personal') // 사업자 선택 버튼 상태

  const handlePostCodeSelect = (selectedAddress) => {
    setValue('postNo', selectedAddress.zip_code)
    setValue('addrNo1', selectedAddress.road_address)
    setValue('addrNo2', '')
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (data) {
      setValue('coNm', data.DOJANG_NAME)
      setValue('coNo', data.BR_NUMBER)
      // 주소
      setValue('addrNo1', data.ROAD_ADDRESS)
      setValue('addrNo2', data.ADDRESS_DETAIL)

      const fullName = `${data.LAST_NAME}${data.FIRST_NAME}`
      setValue('repNm', fullName)
      setValue('contNm', fullName)

      setValue('repBirthDt', data.DOB)
      setValue('repHp', data.REP_PHONE_NUMBER)
      setValue('telNo', data.REP_PHONE_NUMBER)
      setValue('contTel', data.REP_PHONE_NUMBER)
      setValue('EMAIL', data.EMAIL)

      setValue('')
    }
  }, [data, setValue])

  const mutation = useMutation((formData) => createPGState(dojang_id, formData), {
    onSuccess: (data) => {
      console.log('PG 가입 성공:', data)
      alert('PG 가입 요청이 성공적으로 완료되었습니다.')
    },
    onError: (error) => {
      console.log('PG 가입 실패:', error)
      alert('PG 가입 요청에 실패했습니다. 다시 시도해주세요.')
    },
  })

  const onSubmit = async (formData) => {
    if (businessType === 'corporate' && !formData.coRegNo) {
      // 법인등록번호가 비어있으면 에러 설정
      setError('coRegNo', {
        type: 'manual',
        message: '법인등록번호를 입력해주세요.',
      })
      return
    }
    // formData에 필요한 데이터가 모두 담겨있는지 확인한 후 mutation 실행
    mutation.mutate({
      coNm: formData.coNm,
      coNo: formData.coNo,
      openDt: formData.openDt,
      repNm: formData.repNm,
      repBirthDt: formData.repBirthDt,
      email: formData.email,
      repHp: formData.repHp,
      telNo: formData.telNo,
      coRegNo: businessType === 'corporate' ? formData.coRegNo : '',
      postNo: formData.postNo,
      addrNo1: formData.addrNo1,
      addrNo2: formData.addrNo2,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex flex-col gap-[20px] justify-center items-center'>
        {/* 상호 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label='상호'
            req={true}
          />
          <InputControlled
            name='coNm'
            control={control}
            rules={{
              required: '상호를 입력해주세요.',
              minLength: {
                value: 2,
                message: '최소 2자 이상 입력해주세요.',
              },
            }}
            placeholder='상호를 입력해주세요.'
          />
        </FlexWrap>

        {/* 사업자번호 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label='사업자번호'
            req={true}
          />
          <InputControlled
            name='coNo'
            control={control}
            rules={{
              required: '사업자 번호를 입력해주세요.',
              minLength: {
                value: 2,
                message: '최소 2자 이상 입력해주세요.',
              },
            }}
            placeholder='사업자 번호를 입력해주세요.'
          />
        </FlexWrap>

        {/* 사업자 선택 버튼 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label='사업자선택'
          />
          <PrimaryButton
            width='156px'
            onClick={() => setBusinessType('personal')}
            minHeight='39px'
            className={` ${
              businessType === 'personal'
                ? 'bg-blue-500 text-white font-bold border-[#EEEEEE]'
                : 'bg-#[EEEEEE] border-[#EEEEEE]'
            }`}
          >
            개인사업자
          </PrimaryButton>
          <PrimaryButton
            width='156px'
            onClick={() => setBusinessType('corporate')}
            minHeight='39px'
            className={` ${
              businessType === 'corporate'
                ? 'bg-blue-500 text-white font-bold border-[#EEEEEE]'
                : 'bg-#[EEEEEE] border-[#EEEEEE]'
            }`}
          >
            법인사업자
          </PrimaryButton>
        </FlexWrap>

        {/* 법인등록번호 */}
        {businessType === 'corporate' && (
          <FlexWrap className='items-start w-1/2'>
            <AlignLabel
              className='mt-2'
              label='법인등록번호'
            />
            <InputControlled
              name='coRegNo'
              control={control}
              rules={{
                minLength: {
                  value: 13,
                  message: '법인등록번호는 13자리여야 합니다.',
                },
                maxLength: {
                  value: 13,
                  message: '법인등록번호는 13자리를 초과할 수 없습니다.',
                },
              }}
              placeholder='법인사업자일 경우 입력해주세요.'
            />
          </FlexWrap>
        )}

        {/* 사업개시일 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label='사업개시일'
            req={true}
          />
          <InputControlled
            name='openDt'
            control={control}
            rules={{
              pattern: {
                value: /^\d{8}$/,
                message: '사업개시일은 8자리로 입력해주세요. ex)19990101',
              },
              required: '사업개시일을 입력해주세요.',
            }}
            placeholder='사업개시일을 8자리로 입력해주세요. ex)19990101'
          />
        </FlexWrap>

        {/* 대표자명 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label='대표자명'
            req={true}
          />
          <InputControlled
            name='repNm'
            control={control}
            rules={{
              required: '대표자명을 입력해주세요.',
              minLength: {
                value: 2,
                message: '대표자명은 2자 이상 입력해주세요.',
              },
            }}
            placeholder='대표자명을 입력해주세요.'
          />
        </FlexWrap>

        {/* 대표자 생년월일 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label='대표자 생년월일'
            req={true}
          />
          <InputControlled
            name='repBirthDt'
            control={control}
            rules={{
              required: '대표자 생년월일을 입력해주세요.',
              pattern: {
                value: /^\d{8}$/,
                message: '생년월일은 8자리로 입력해주세요. ex)19990101',
              },
            }}
            placeholder='생년월일을 8자리로 입력해주세요. ex)19990101'
          />
        </FlexWrap>

        {/* 대표자 HP */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label='대표자 HP'
            req={true}
          />
          <InputControlled
            name='repHp'
            control={control}
            rules={{
              required: '대표자 HP을 입력해주세요.',
              pattern: {
                value: /^01[0|1|6|7|8|9]\d{7,8}$/,
                message: '유효한 휴대폰 번호 형식을 입력해주세요. ex)01012345678',
              },
              minLength: {
                value: 10,
                message: '휴대폰 번호는 최소 10자리여야 합니다.',
              },
              maxLength: {
                value: 11,
                message: '휴대폰 번호는 최대 11자리여야 합니다.',
              },
            }}
            placeholder='대표자 휴대폰 번호를 입력해주세요. 하이픈(-) 없이 입력. 예: 01012345678'
          />
        </FlexWrap>

        {/* 전화번호 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label='전화번호'
          />
          <InputControlled
            name='telNo'
            control={control}
            placeholder='전화번호를 입력해주세요.'
          />
        </FlexWrap>

        {/* 이메일 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label='email'
            req={true}
          />
          <InputControlled
            name='email'
            control={control}
            rules={{
              required: '이메일을 입력해주세요.',
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: '유효한 이메일 형식을 입력해주세요.',
              },
            }}
            placeholder='이메일을 입력해주세요.'
          />
        </FlexWrap>

        {/* 우편번호 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label='우편번호'
            req={true}
          />
          <InputControlled
            name='postNo'
            control={control}
            onClick={() => setIsModalOpen(true)}
            rules={{
              required: '우편번호를 입력해주세요.',
            }}
            value={getValues('postNo')}
            placeholder='우편번호를 입력하려면 클릭하세요.'
          />
        </FlexWrap>

        {/* 주소 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label='주소'
            req={true}
          />
          <InputControlled
            className='pr-[20px]'
            name='addrNo1'
            control={control}
            rules={{
              required: '주소를 입력해주세요.',
            }}
            value={getValues('addrNo1')}
            placeholder='주소를 입력해주세요.'
          />
          <InputControlled
            name='addrNo2'
            control={control}
            rules={{
              required: '상세주소를 입력해주세요.',
              minLength: {
                value: 2,
                message: '최소 2자 이상 입력해주세요.',
              },
            }}
            placeholder='상세주소를 입력해주세요.'
          />
        </FlexWrap>

        {/* 우편번호 등록 모달 */}
        <ModalAddress
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          control={true}
          setValue={handlePostCodeSelect}
        />

        <FlexWrap className='pt-[20px]'>
          <PgSignButton />
        </FlexWrap>
      </div>
    </form>
  )
}

export default PgSignForm
