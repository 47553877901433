import React from 'react'
import PGGuideHeader from './PGGuideHeader'
import PGIntroContent from './PGIntroContent'
import PGIntroMobile from './PGIntroMobile'
import PGInfo from './PGInfo'
import PGInfoTable from './PGInfoTable'
import PGFeeTable from './PGFeeTable'

const PgSignComponent = () => {
  return (
    <div className='w-full'>
      <PGGuideHeader />
      <PGIntroContent />
      <PGIntroMobile />
      <PGInfo />
      <PGInfoTable />
      <PGFeeTable />
    </div>
  )
}

export default PgSignComponent
