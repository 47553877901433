import React, { useRef } from 'react'
import { FormControl, FormHelperText, InputLabel, Select as MUISelect, MenuItem } from '@mui/material'
import { useDebug } from '../../../hooks/basic/useDebug'
import Typography from '../TEXT/Typography'

const SelectStyle = {
  fontSize: '18px',
  fontWeight: '700',
  margin: 0,
  // label 위치
  '& .MuiInputLabel-root': {
    top: '-7px',
    left: '0px',
    color: '#000',
    backgroundColor: 'transparent',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    top: '0px',
    left: '-5px',
    width: 'auto',
    color: '#000',
  },
  '& .MuiInputLabel-shrink': {
    top: '0',
    padding: '0 5px',
    left: '-5px',
    // display: 'none',
  },
  '& .MuiInputBase-root': {
    borderRadius: '5px',
    backgroundColor: 'transparent',
  },

  '& .MuiOutlinedInput-input': {
    // border: '1px solid #2E5CFF',
    backgroundColor: '#fff',
    borderRadius: '5px',
    paddingLeft: '10px',
    textAlign: 'left',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& .MuiAutocomplete-input': {
      padding: '0',
    },
    '&:focus': {
      borderRadius: '5px',
    },
  },

  // error text message
  '& .MuiFormHelperText-root': {
    marginLeft: '0',
  },
  '& .MuiSelect-icon': {
    color: '#555555',
  },
  '& .MuiSvgIcon-root': {
    // position: "initial",
    marginRight: '',
  },
}

const MenuItemStyle = {
  backgroundColor: '#fff',
  '&.Mui-selected': {
    backgroundColor: '#F6EAE1',
    '&:hover': {
      backgroundColor: '#F6EAE1',
    },
  },
}

const Select = React.forwardRef(
  (
    {
      error,
      helperText,
      width,
      menuWidth,
      minWidth,
      padding,
      noPaddingRight,
      border,
      label,
      item,
      size,
      sx,
      children,
      noHelperText,
      ...props
    },
    ref,
  ) => {
    const InputRef = useRef()
    return (
      <FormControl
        ref={InputRef}
        error={error}
        sx={{
          minWidth: width || '250px',
          width: width || '250px',
          '& .MuiOutlinedInput-input': {
            ...SelectStyle['& .MuiOutlinedInput-input'],
            width: width || '250px',
            border: border || '1px solid #EEEEEE',
            padding: padding || '11.5px 15px',
          },
          '& .MuiSelect-select': {
            paddingRight: noPaddingRight ? '0px !important' : '',
          },
          '& .MuiSelect-icon': {
            right: padding ? '0px' : '',
            // paddingRight: '0px',
          },
          ...sx,
        }}
      >
        <InputLabel className='bg-[#fff]'>{label}</InputLabel>
        <MUISelect
          inputRef={ref}
          size={size}
          onClose={(e) => {
            InputRef.current.lastChild.classList.remove('Mui-focused')
          }}
          onOpen={(e) => {
            InputRef.current.lastChild.classList.add('Mui-focused')
          }}
          MenuProps={{
            disableScrollLock: true,
            PaperProps: {
              style: {
                maxHeight: '180px',
                width: menuWidth || width || '250px',
              },
            },
          }}
          {...props}
        >
          {children}
          {React.Children.toArray(
            item?.map((row) => (
              <MenuItem
                value={row?.id ?? ''}
                sx={{
                  ...MenuItemStyle,
                  minWidth: minWidth || 'auto',
                  width: width || menuWidth || 'auto',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'block',
                }}
              >
                {row?.title}
              </MenuItem>
            )),
          )}
        </MUISelect>
        {helperText && !noHelperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  },
)

Select.displayName = 'Select'

export default Select
