import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import Typography from '../../../../../UI/ATOM/TEXT/HOME/Typography'
import PrimaryButton from '../../../../../UI/ATOM/BUTTON/PrimaryButton'

const RequestPgButton = () => {
  const navigate = useNavigate()
  const handleRequestClick = () => {
    alert(
      '현재 온라인 접수를 받지 않고 있습니다.\n\n아래번호로 문의주시면, 안내를 도와드리겠습니다.\n태권월드 CS팀 010-7615-6646\n\n감사합니다.',
    )
    // navigate to request-pg page
    // navigate('/service/pg/signForm')
  }

  return (
    <PrimaryButton
      width='340px'
      height='38px'
      variant='contained'
      type='submit'
      onClick={handleRequestClick}
    >
      <Typography className='font-bold text-[16px]'>
        <FormattedMessage id='PG 가입요청' />
      </Typography>
    </PrimaryButton>
  )
}

export default RequestPgButton
