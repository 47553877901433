import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import LoadingModal from '../../MOLECULES/LOADING/LoadingModal'
import Typography from '../../ATOM/TEXT/Typography'
import SideBarTrial from './MENU/SideBarTrial'

const LayoutDojangManagementTrial = () => {
  return (
    <>
      <div className='bg-body'>
        <div className='main-wrap'>
          <SideBarTrial />
          <Suspense fallback={<LoadingModal />}>
            <Outlet />
          </Suspense>
          <div className='fixed bottom-3 right-4 bg-[#3f89ff] w-[300px] z-50 h-[60px] rounded-md flex justify-center items-center'>
            <Typography
              variant='h5'
              className='text-[#fff]'
            >
              체험판은 일부 기능만 표시됩니다. <br /> 자세한 내용은 서비스 소개를 참고해주세요.
            </Typography>
          </div>
        </div>
      </div>
    </>
  )
}

export default LayoutDojangManagementTrial
