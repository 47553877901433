import React from 'react'
import Button from './Button'

const buttonStyle = {
  border: '1px solid #A5C2FF',
  borderRadius: '4px',
  fontSize: '12px',
  padding: '4px 8px',
  color: '#191919',
  '&.MuiButton-outlined': {
    backgroundColor: 'var(--color-button-quinary)',
  },
  '&.MuiButton-root:hover, &.MuiButton-root:focus, &.MuiButton-root:active': {
    backgroundColor: '#D8DFFC',
    border: '1px solid #A5C2FF',
    boxShadow: '0',
  },
}

const QuinaryButton = ({ width, height, sx, children, ...props }) => {
  return (
    <Button
      width={width || '100%'}
      height={height || '30px'}
      sx={{ ...buttonStyle, ...sx }}
      {...props}
    >
      {children}
    </Button>
  )
}

export default QuinaryButton
