import React from 'react'
import { Typography as MUITypography } from '@mui/material'

// h2, h3 = bold (mui themes => index.js)

const fontSizeStyle = {
  h1: {fontSize: 26, fontWeight: 'bold'},
  h2: {fontSize: 22, fontWeight: 'normal'},
  h3: {fontSize: 20, fontWeight: 'normal'},
  h4: {fontSize: 18},
  h5: {fontSize: 16},
  h6: {fontSize: 14},
  p: {fontSize: 12}
};

const Typography = ({ bold, leading, variant, sx: additionalStyles, className, children, ...props }) => {
  const fontStyle = { ...fontSizeStyle[variant], ...additionalStyles };
  if (bold) {
    fontStyle.fontWeight = '700';
  }
  if (leading) {
    fontStyle.lineHeight = leading;
  }

  return (
    <MUITypography variant={variant} sx={fontStyle} className={className} {...props}>
      {children}
    </MUITypography>
  )
}

export default Typography