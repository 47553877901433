import DaumPostcode from 'react-daum-postcode'

const PostCode = ({ setValue, onClose, controlled, upper, ...props }) => {
  // 우편번호 검색 후 주소 클릭 시 실행될 함수, data callback 용

  const handlePostCode = (data) => {
    let depthAddr = data.jibunAddress.split(' ')
    // console.log(data)
    let addressInfo = upper
      ? {
          ADDRESS_NAME: data.jibunAddress ? data.jibunAddress : data?.autoJibunAddress,
          REGION_1DEPTH_NAME: depthAddr[0] ? depthAddr[0] : data?.sido,
          REGION_2DEPTH_NAME: depthAddr[1] ? depthAddr[1] : data?.sigungu,
          REGION_3DEPTH_NAME: depthAddr[2] ? depthAddr[2] : data?.bname,
          REGION_3DEPTH_H_NAME: data?.hname ? data?.hname : data?.bname,
          ROAD_ADDRESS: data.roadAddress,
          ZIP_CODE: data.zonecode,
        }
      : {
          address_name: data.jibunAddress ? data.jibunAddress : data?.autoJibunAddress,
          region_1depth_name: depthAddr[0] ? depthAddr[0] : data?.sido,
          region_2depth_name: depthAddr[1] ? depthAddr[1] : data?.sigungu,
          region_3depth_name: depthAddr[2] ? depthAddr[2] : data?.bname,
          region_3depth_h_name: data?.hname ? data?.hname : data?.bname,
          road_address: data.roadAddress,
          zip_code: data.zonecode,
        }
    // console.log(addressInfo);
    if (controlled) {
      setValue('postNo', addressInfo.ZIP_CODE)
      setValue('addrNo1', addressInfo.ROAD_ADDRESS)
    } else {
      setValue({
        ...addressInfo,
      })
    }
    onClose()
  }

  return (
    <div className='flex flex-col justify-center items-center'>
      <DaumPostcode onComplete={handlePostCode} />
    </div>
  )
}

export default PostCode
