import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { getCurrMonth, getCurrYear } from '../../../../UTILS/basic/getDate'
import FlexWrap from '../../../../UI/LAYOUT/WRAP/FlexWrap'
import Typography from '../../../../UI/ATOM/TEXT/Typography'
import { useNavigate } from 'react-router-dom'
import QuinaryButton from '../../../../UI/ATOM/BUTTON/QuinaryButton'
import ProfileImage from '../../../../UI/MOLECULES/PROFILE/ProfileImage'
import Select from '../../../../UI/ATOM/SELECT/Select'
import { useSnack } from '../../../../hooks/basic/useSnack'
import SnackBar from '../../../../UI/ATOM/SNACKBAR/SnackBar'
import SideBarMenu from './Components/SideBarMenu'

const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL

const SideBarTrial = () => {
  const nav = useNavigate()

  const currYear = getCurrYear().toString()
  const currMonth = getCurrMonth().toString()

  const trialMenu = useMemo(() => {
    return [
      {
        name: 'home',
        title: '홈',
        path: '/trial/home',
        src: `${s3URL}/asset/images/icon/side_menu/home.png`,
      },
      {
        name: 'dashboard',
        title: '대시보드',
        path: 'trial/dashboard',
        src: `${s3URL}/asset/images/icon/side_menu/dashboard.png`,
      },
      {
        name: 'invite',
        title: '초대장',
        src: `${s3URL}/asset/images/icon/side_menu/invite.png`,
        sub: [
          { title: '조회', path: 'trial/invitation/inquiry' },
          { title: '초대장 발송', path: 'trial/invitation/send' },
        ],
      },
      {
        name: 'manage',
        title: '도장 관리',
        src: `${s3URL}/asset/images/icon/side_menu/dojang.png`,
        sub: [
          { title: '도장', path: 'trial/manage/dojang' },
          { title: '사범', path: 'trial/manage/sabum/list' },
          { title: '반', path: 'trial/manage/class/list' },
          { title: '차량', path: 'trial/manage/bus/list' },
          { title: '급띠체계', path: 'trial/manage/levelsystem' },
        ],
      },
      {
        name: 'manage-student',
        title: '수련생',
        src: `${s3URL}/asset/images/icon/side_menu/student.png`,
        sub: [
          { title: '조회', path: '/trial/manage/student/list' },
          { title: '수련생 등록', path: 'trial/manage/student/regist' },
        ],
      },
      {
        name: 'attendance',
        title: '출결',
        src: `${s3URL}/asset/images/icon/side_menu/attendance.png`,
        sub: [
          {
            title: '조회',
            path: '/trial/attendance/status',
            params: { class: 0, y: currYear, m: currMonth },
          },
        ],
      },
      {
        name: 'bill',
        title: '납부',
        src: `${s3URL}/asset/images/icon/side_menu/bill.png`,
        sub: [
          {
            title: '조회',
            // path: '/bill/status'
          },
          {
            title: '청구서 발행',
            // path: '/bill/request/select',
            params: { class: 0 },
          },
          // { title: '결제선생 등록', path: '/service/payssam' },
        ],
      },
      {
        name: 'levelup',
        title: '승급',
        src: `${s3URL}/asset/images/icon/side_menu/levelup.png`,
        sub: [
          {
            title: '조회',
            path: '/trial/levelup/history',
          },
          {
            title: '승급 등록',
            // path: '/levelup/regist/select',
            // params: { class: 0 },
          },
        ],
      },
    ]
  }, [currYear, currMonth])

  const image = {
    logo: `${s3URL}/asset/images/logo/TKW_LOGO.png`,
  }

  // useDebug(data)

  const [fn, openFn] = useSnack()

  return (
    <>
      <div className='side-container overflow-y-auto scrollbar-hide'>
        <div className='side-profile'>
          <a
            href='/'
            className='flex justify-center'
          >
            <img
              src={image.logo}
              alt='TAEKWON WORLD'
              className='w-[190px] mb-[20px] object-cover'
            />
          </a>
          <div className='side-profile-img'>
            <ProfileImage size='58px' />
            <div className='flex flex-col gap-1'>
              <p className='text-center'>김관장 관장님</p>
              <FlexWrap className='gap-2'>
                <Typography
                  variant='h6'
                  bold
                  className='cursor-pointer text-[#777777]'
                  onClick={() => openFn()}
                >
                  내 정보
                </Typography>
                <Typography
                  variant='h6'
                  bold
                  className='cursor-pointer text-[#777777]'
                  onClick={() => nav('/logout')}
                >
                  로그아웃
                </Typography>
              </FlexWrap>
            </div>
          </div>
        </div>
        <FlexWrap className='gap-[10px] mt-[10px]'>
          <QuinaryButton onClick={() => openFn()}>
            <FormattedMessage id='새 도장 등록하기' />
          </QuinaryButton>
          <QuinaryButton onClick={() => openFn()}>
            <FormattedMessage id='등록된 도장 보기' />
          </QuinaryButton>
        </FlexWrap>
        <div className='side-profile-btn'>
          <Select
            width={'100%'}
            item={[
              {
                id: '1',
                title: '태권월드 태권도',
              },
            ]}
            value={'1'}
            sx={{
              '& .MuiOutlinedInput-input': {
                textAlign: 'center',
                backgroundColor: '#fff',
                borderRadius: '5px',
                paddingLeft: '10px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                '& .MuiAutocomplete-input': {
                  padding: '0',
                },
                '&:focus': {
                  borderRadius: '5px',
                },
                border: '1px solid #EEEEEE',
                padding: '11.5px 15px',
              },
            }}
          />
        </div>
        <div className='mt-[10px]'>
          <SideBarMenu menu={trialMenu} />
        </div>
      </div>

      <SnackBar {...fn} />
    </>
  )
}

export default SideBarTrial
