import { Typography as MUITypography } from '@mui/material'
import React from 'react'

const FontSizeStyle = {
  h1: { fontSize: 38 },
  h2: { fontSize: 36 },
  h3: { fontSize: 30 },
  h4: { fontSize: 24 },
  h5: { fontSize: 20 },
  h6: { fontSize: 18 },
  button: { fontSize: 22 },
  title: { fontSize: 42, fontWeight: 'bold' },
  subtitle: { fontSize: 24 },
  p1: { fontSize: 16 },
  p2: { fontSize: 14 },
  p3: { fontSize: 12 },
  p4: { fontSize: 10 },
}

const Typography = ({ children, variant, bold, sx: additionalStyles, className, leading, ...props }) => {
  const fontStyle = { ...FontSizeStyle[variant], ...additionalStyles }
  if (bold) {
    fontStyle.fontWeight = 'bold'
  }
  if (leading) {
    fontStyle.lineHeight = leading
  }

  return (
    <MUITypography
      sx={fontStyle}
      variant={variant}
      className={className}
      {...props}
    >
      {children}
    </MUITypography>
  )
}

export default Typography
