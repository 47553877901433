import React from 'react'
import styled from '@mui/material/styles/styled'
import { Box } from '@mui/material'
import { useDebug } from '../../../hooks/basic/useDebug'

const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL

const ProfileImage = React.forwardRef(({ noneBorder, ...props }, ref) => {
  const ImageBox = styled(Box)(({ src, width, height, size, shape }) => ({
    width: width || size,
    height: height || size,
    backgroundColor: '#ffffff',
    borderRadius: shape,
    transition: 'all 0.3s ease',
    border: noneBorder ? '0 none' : '1px solid #bbbbbb',
    position: 'relative',
    overflow: 'hidden',
  }))

  const defaultProps = {
    src: `${s3URL}/asset/images/image/profile/default_profile.png`,
    size: '40px',
    shape: '50%',
    disabled: true,
  }

  const newProps = { ...defaultProps, ...props }

  const { size, shape, disabled, width, height } = newProps

  const urlKey = props?.urlKey

  const getSrc = () => {
    if (props?.previewUrl) {
      return props?.previewUrl
    }
    if (!!urlKey && urlKey != 'null') {
      return `${s3URL}/${urlKey}`
    }
    return defaultProps.src
  }
  const styles = {
    src: getSrc(),
    size: size,
    shape: props.full ? '' : shape,
    width: width || height || size,
    height: height || width || size,
  }

  return (
    <div>
      <ImageBox
        width={styles.width}
        height={styles.height}
        size={size}
        shape={props.full ? '' : shape}
        {...styles}
      >
        <img
          // ref={ref} ????????????????????????????
          src={styles.src}
          alt='Profile'
          style={{
            minWidth: styles.width,
            width: styles.width,
            height: styles.height,
            objectFit: props.full ? 'contain' : 'cover',
          }}
        />
      </ImageBox>
      <input
        ref={ref}
        type='file'
        accept='image/*'
        onChange={props?.onChange}
        className='hidden'
      />
    </div>
  )
})

ProfileImage.displayName = 'ProfileImage'
export default React.memo(ProfileImage)
