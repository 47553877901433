import moment from 'moment'

const curr = new Date()

export function getCurrYear() {
  return curr.getFullYear()
}

export function getCurrMonth() {
  return curr.getMonth() + 1
}

export function getCurrDate() {
  return curr.getDate()
}

export function getCurrFullDate() {
  return `${curr.getFullYear()}. ${curr.getMonth() + 1}. ${curr.getDate()}`
}

export const monthList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']

export const dayList = Array.from({ length: 31 }, (v, i) => i + 1)

export const getCurrDateString = () => {
  return moment().format('YYYYMMDD')
}
