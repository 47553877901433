import React from 'react'
import { styled } from '@mui/system'
import { Typography } from '@mui/material'

const TitleStyle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.font.weight.bold,
  // fontSize: '20px',
  lineHeight: 1.5,
  paddingBottom: '15px',
  marginBottom: '20px',
  borderBottom: '1px solid #BDBDBD',
  color: '#000000',

}));

const Title = ({children}) => {
  return (
    <>
      <TitleStyle className='flex items-center justify-between' variant="h2" component="h2">
        {children}
      </TitleStyle>
    </>
  )
}

export default Title