import React from 'react'
import Container from '../../../../UI/LAYOUT/CONTAINER/Container'
import { intlTransLang } from '../../../../UTILS/render/TRANSLATION/transLang'
import { useIntl } from 'react-intl'
import ApiErrorBoundary from '../../../../UI/TEMPLATES/ERROR/ErrorBoundary/ApiErrorBoundary'
import FlexWrap from '../../../../UI/LAYOUT/WRAP/FlexWrap'
import PgSignForm from './Components/PgSignForm'

const PgSignComponent = () => {
  // intl
  const intl = useIntl()

  return (
    <>
      <Container title={<FlexWrap className='items-center gap-[15px]'>{intlTransLang(intl, 'PG 가입요청')}</FlexWrap>}>
        <ApiErrorBoundary>
          <PgSignForm />
        </ApiErrorBoundary>
      </Container>
    </>
  )
}

export default PgSignComponent
