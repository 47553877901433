import React from 'react'
import FlexWrap from '../../../../../UI/LAYOUT/WRAP/FlexWrap'
import { FormattedMessage } from 'react-intl'
import FlexColWrap from '../../../../../UI/LAYOUT/WRAP/FlexColWrap'

const BillFooter = () => {
  return (
    <FlexWrap className='flex-col w-full pt-[30px]'>
      <FlexWrap className='w-full justify-between text-[#757575]'>
        <p className='font-bold'>태권월드</p>
        <p className='underline italic'>https://taekwonworld.net</p>
      </FlexWrap>

      <FlexColWrap className='gap-[3px] text-[#6D6D6D] pb-[20px]'>
        <div className='flex flex-col text-start'>
          <span className='text-sm'>
            <FormattedMessage id='주식회사 에이아이빌' />
          </span>
          <span className='text-sm'>
            <FormattedMessage id='서울 영등포구 국회대로 72길 407호' />
          </span>
          <span className='text-sm'>
            <FormattedMessage id='사업자 등록번호 894-8802212' />
          </span>
        </div>
      </FlexColWrap>
    </FlexWrap>
  )
}

export default BillFooter
