import { FormattedMessage } from 'react-intl'

export const intlTransLang = (intl, id, ...args) => {
  const trans = intl.formatMessage({ id: id }, ...args)
  return trans
}

export const formattedTransLang = (id, values) => {
  const trans = (
    <FormattedMessage
      id={id}
      values={values}
    />
  )
  return trans
}
