import React from 'react'

const FlexWrap = ({ className, children, ...props }) => {
  return (
    <div
      className={`flex items-start ${className ?? ''}`}
      {...props}
    >
      {children}
    </div>
  )
}

export default FlexWrap
