import React from 'react'
import { FormattedMessage } from 'react-intl'

// s3URL
const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL

const GuideTextTooltip = ({ onClick }) => {
  return (
    <div
      className='flex items-center cursor-pointer gap-[2px]'
      onClick={onClick}
    >
      <span className='underline underline-offset-2 text-[15px] text-[var(--color-font-blue)] font-normal'>
        <FormattedMessage id='사용 가이드' />
      </span>
      <img
        src={`${s3URL}/guide/icon/admin/guide_expand_icon.png`}
        alt='expand_icon'
      />
    </div>
  )
}

export default GuideTextTooltip
