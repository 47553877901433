export const countryCode = JSON.parse(sessionStorage.getItem('country_code'));

// console.log(countryCode)

export const addCountryCode = (body) => {
    const getBody = {
        ...body,
        country_code : countryCode
    }
    return getBody;
}