import React from 'react'
import { Controller } from 'react-hook-form'
import Input from '../../../ATOM/INPUT/Input'
import { requiredRule } from '../../../../UTILS/VALIDATION/RULES/rules'

const InputControlled = React.forwardRef(
  ({ name, control, rules, required, defaultValue, onChange, inputProps, numeric, noHelperText, ...props }, ref) => {
    return (
      <Controller
        name={name || ''}
        control={control || ''}
        rules={{
          required: required ? requiredRule.required : false,
          ...rules,
        }}
        defaultValue={defaultValue ?? ''}
        render={({ field, fieldState: { error }, ref: inputRef }) => (
          <Input
            ref={inputRef}
            error={Boolean(error?.message)}
            helperText={!noHelperText ? error?.message : ''}
            inputProps={inputProps}
            {...field}
            {...props}
            // value={field.value ?? ''}
            value={(numeric ? field.value?.toLocaleString('ko-kr') : field?.value) ?? ''}
            onChange={(e) => {
              field.onChange(e)
              if (onChange) onChange(e)
            }}
          />
        )}
      />
    )
  },
)

InputControlled.displayName = 'InputControlled'
export default InputControlled
