// ID 체크
const idRegex = new RegExp(/^(?=.*[a-z])[a-z0-9_-]{6,20}$/)

// ^(?=.*[a-z])[a-zA-Z0-9]{6,20}$

// 한글+영문 2글자 이상 20글자 이하 체크
const nameRegex = new RegExp(/^[가-힣]{1,6}$/)

// 성 regex 체크
const lastNameRegex = new RegExp(/^[가-힣]{1,3}$/)

//email형식 체크
// const emailRegex = new RegExp(/(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/);
const emailRegex = new RegExp(
  /^[a-zA-Z0-9._%+-]+[@][a-zA-Z0-9.-]+[.][a-zA-Z]{2,}(?:[.a-zA-Z]{2,})?$/
)

// 비밀번호 영어소문자+숫자+글자수(8글자 이상, 20글자 이하) 체크
const passwordRegex = new RegExp(
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?~^<>,.&+=])[A-Za-z\d$@$!%*#?~^<>,.&+=]{8,20}$/
)

// /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?!.*\s)[A-Za-z\d\w\W]{8,20}$/

// 비밀번호 일치 확인
const passwordConfirmRegex = (password, passwordConfirm) => {
  return password === passwordConfirm
}

// 휴대전화번호 형식 체크
const phoneRegex = new RegExp(/^\d{3}-\d{3,4}-\d{4}$/)

// 숫자만 입력받기
const numberRegex = (value) => {
  return value.replace(/[^0-9]/g, '')
}

// 휴대전화번호 하이폰(-) 정규식
const phoneHyphenRegex = (phone) => {
  return (
    phone &&
    phone
      ?.replace(/[^0-9]/g, '')
      ?.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
      ?.replace(/(\-{1,2})$/g, '')
  )
}

// 숫자 1000단위 콤마 찍기 함수
const numberWithCommas = (value) => {
  return value
    .toString()
    .replace(
      /(\..*)$|(\d)(?=(\d{3})+(?!\d))/g,
      (digit, fract) => fract || digit + ','
    )
}

// 사업자 등록 번호 하이폰(-) 정규식
const businessNumberHyphenRegex = (businessNumber) => {
  return (
    businessNumber &&
    businessNumber
      ?.replace(/[^0-9]/g, '')
      ?.replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, '$1-$2-$3')
      ?.replace(/(\-{1,2})$/g, '')
  )
}

// 지역, 대표, 휴대전화 번호 정규식
export const hyphenReg = (num) => {
  return (
    num &&
    num
      ?.replace(/[^0-9]/g, '')
      ?.replace(/^(02|0[3-6]{1}[1-5]{1})([0-9]{3,4})([0-9]{4})$/, '$1-$2-$3')
      ?.replace(
        /^(02|0[3-6]{1}[1-5]{1})(15|16|18)[0-9]{2}([0-9]{4})$/,
        '$1-$2-$3'
      )
      ?.replace(/^(1[0-9]{3})([0-9]{4})$/, '$1-$2')
      ?.replace(
        /^(070|050[2-8]{0,1}|080|013)([0-9]{3,4})([0-9]{4})$/,
        '$1-$2-$3'
      )
      ?.replace(/^(01[016789]{1})([0-9]{3,4})([0-9]{4})$/, '$1-$2-$3')
      ?.replace(/(\\-{1,2})$/g, '')
  )
}

export {
  idRegex,
  nameRegex,
  lastNameRegex,
  emailRegex,
  passwordRegex,
  numberRegex,
  phoneRegex,
  phoneHyphenRegex,
  passwordConfirmRegex,
  numberWithCommas,
  businessNumberHyphenRegex,
}
