import React from 'react'
import Button from './Button'

const buttonStyle = {
  border: '1px solid #333333',
  borderRadius: '2px',
  color: '#191919',
  backgroundColor: '#fff',
  fontWeight: '500',
  lineHeight: '18px',
  padding: '5px 15px',
  boxShadow: '0',
  '&.MuiButton-root': {
    '&:hover, &:active': {
      backgroundColor: '#F5F5F5',
      border: '1px solid #000',
      boxShadow: '0',
    },
  },
  '&.Mui-disabled': {
    border: '1px solid #D9D9D9',
    color: '#777777',
  },

  // contained
  '&.MuiButton-contained': {
    color: '#fff',
    backgroundColor: '#2E5CFF',
    border: '0 none',
    '&.Mui-disabled': {
      border: '1px solid #DADADA',
      backgroundColor: '#DADADA',
      color: '#fff',
    },
  },
  '&.MuiButton-contained:hover': {
    backgroundColor: '#4A72FF',
    color: '#fff',
    border: '0 none',
  },
  '&.MuiButton-contained:focus': {
    backgroundColor: '#4A72FF',
    color: '#fff',
    border: '0 none',
  },
}

const PrimaryButton = React.forwardRef(
  ({ minWidth, minHeight, width, height, fontSize, sx, children, ...props }, ref) => {
    return (
      <Button
        sx={{
          ...buttonStyle,
          ...sx,
        }}
        minWidth={minWidth || '146px'}
        minHeight={minHeight || '42px'}
        width={width || 'auto'}
        height={height || 'auto'}
        fontSize={fontSize || '16px'}
        ref={ref}
        {...props}
      >
        {children}
      </Button>
    )
  },
)

PrimaryButton.displayName = 'PrimaryButton'

export default PrimaryButton
