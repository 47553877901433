export const ContainerSizePathList = [
  '/dojang/regist',
  '/dojang/remove',
  '/mypage/selectinvitation',
  '/service/payment/request',
  '/service/payment/request/success',
  '/service/payssam/request',
  '/account/sign',
  '/account/myinfo',
  '/account/resume',
]
