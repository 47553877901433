import React, { useEffect, useState } from 'react'
import Button from './Button'

const buttonStyle = {
  border: '1px solid #777777',
  borderRadius: '2px',
  color: '#555555',
  padding: '5px 15px',
  boxShadow: '0',
  lineHeight: '18px',
  '&.MuiButton-outlined': {
    backgroundColor: '#ffffff',
    color: '#555555',
    // fontSize: '16px'
  },
  '&.MuiButton-root': {
    '&:hover, &:active': {
      backgroundColor: '#f5f5f5',
      border: '1px solid #2f5df2',
      color: '#555555',
      boxShadow: '0',
    },
  },

  // contained
  '&.MuiButton-contained': {
    color: '#fff',
    backgroundColor: '#2E5CFF',
    border: '0 none',
    // fontSize: '16px'
  },
  '&.MuiButton-contained:hover': {
    backgroundColor: '#5E81F4',
    color: '#fff',
    border: '0 none',
  },
  '&.MuiButton-contained:focus': {
    backgroundColor: '#5E81F4',
    color: '#fff',
    border: '0 none',
  },

  // disabled
  '&.Mui-disabled': {
    backgroundColor: '#aaaaaa',
  },
}

const SecondaryButton = React.forwardRef(
  ({ minWidth, minHeight, width, height, fontSize, sx, children, ...props }, ref) => {
    const [locale, setLocale] = useState(localStorage.getItem('locale') || '')
    const defaultFontSize = locale === 'ko' ? '16px' : '14px'
    const adjustedFontSize = fontSize || defaultFontSize

    useEffect(() => {
      const storedLocale = localStorage.getItem('locale')
      if (storedLocale) {
        setLocale(storedLocale)
      }
    }, [])

    return (
      <Button
        sx={{
          fontSize: adjustedFontSize,

          ...buttonStyle,
          ...sx,
        }}
        minWidth={minWidth || '70px'}
        minHeight={minHeight || '30px'}
        width={width || 'auto'}
        height={height || 'auto'}
        ref={ref}
        {...props}
      >
        {children}
      </Button>
    )
  },
)

SecondaryButton.displayName = 'SecondaryButton'
export default SecondaryButton
