import React from 'react'
import { IconButton } from '@mui/material'
import styled from 'styled-components'

const iconButtonStyle = {
  padding: 0,
  // paddingBottom: '4px',
  marginRight: '5px',
}

const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL

const Button = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background-color: none;
  background-image: url(${s3URL}/asset/images/icon/arrow/charm_arrow-left.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px;
  cursor: pointer;
`

const BackButton = ({ onClick, sx, children }) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{ ...iconButtonStyle, ...sx }}
    >
      <Button>{children}</Button>
    </IconButton>
  )
}

export default BackButton
