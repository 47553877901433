import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '../../../../../UI/ATOM/TEXT/HOME/Typography'
import PrimaryButton from '../../../../../UI/ATOM/BUTTON/PrimaryButton'
import { createPGState } from '../../../../../api/Payment/payment'
import { useMutation } from '@tanstack/react-query'
import dojangIdAtom from '../../../../../recoil/atom/dojangIdAtom'
import { useRecoilValue } from 'recoil'
import RequestPgButton from './RequestPgButton'

const PGGuideHeader = () => {
  const [isRequested, setIsRequested] = useState(false)
  // const dojang_id = useRecoilValue(dojangIdAtom)

  // const { mutate, isLoading, isError } = useMutation(() => createPGState(dojang_id), {
  //   onSuccess: () => {
  //     setIsRequested(true)
  //   },
  //   onError: (error) => {
  //     console.error('가입 요청 중 오류 발생:', error)
  //     // 오류 처리를 원하는 대로 처리 가능
  //   },
  // })

  // const handleRequestClick = () => {
  //   // mutate()
  //   alert('현재 온라인 접수를 받지 않고 있습니다.\n\n아래번호로 문의주시면, 안내를 도와드리겠습니다.\n태권월드 CS팀 010-7615-6646\n\n감사합니다.')
  // }

  return (
    <div className='py-[40px] bg-[#ECF4FF] text-center'>
      <Typography className='text-[38px] font-bold'>
        <FormattedMessage id='태권월드 결제서비스 안내' />
      </Typography>
      <Typography className='text-[20px] pt-[15px]'>
        <FormattedMessage id='태권월드 결제서비스는 청구서를 발송하여 학부모님의 결제를 유도하고,' />
      </Typography>
      <Typography className='text-[20px]'>
        <FormattedMessage id='청구서관리/납부관리를 통해 태권도장의 효율적인 매출관리를 지원합니다.' />
      </Typography>

      <div className='flex pt-[15px] justify-center'>
        {!isRequested ? (
          <div className='pt-[30px]'>
            <RequestPgButton />
          </div>
        ) : (
          <div className='w-[665px] bg-white p-[30px] rounded-lg shadow-lg'>
            <Typography className='text-[23px] text-[#14AE5C] pb-[15px]'>
              <FormattedMessage id='가입 심사 중 입니다.' />
            </Typography>
            <Typography className='text-[20px] text-[#000000]'>
              <FormattedMessage id='승인완료 시 결제서비스(청구서결제)를 바로 이용하실 수 있습니다.' />
            </Typography>
            <Typography className='text-[20px] text-[#000000] pb-[20px]'>
              <FormattedMessage id='승인완료 시 이메일, SMS가 발송됩니다.' />
            </Typography>
            <Typography className='text-[20px] text-[#000000]'>
              <FormattedMessage id='가입요청일' />: 2024.10.01 13:33:00
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default PGGuideHeader
