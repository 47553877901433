import React from 'react'
import Typography from '../../../../../UI/ATOM/TEXT/HOME/Typography'
import { FormattedMessage } from 'react-intl'

const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL

const itemList = [
  {
    url: `${s3URL}/pay/payment_intro_2_1.png`,
  },
  {
    url: `${s3URL}/pay/payment_intro_2_2.png`,
  },
  {
    url: `${s3URL}/pay/payment_intro_2_3.png`,
  },
]

const PGIntroMobile = () => {
  return (
    <div className='px-[50px] py-[50px]'>
      <div>
        <Typography className='text-[#000000] text-[20px]'>
          <FormattedMessage id='[결제 안내]' />
        </Typography>
      </div>
      <div>
        <div className='grid grid-cols-3 justify-center items-center py-[20px] px-[30px] gap-[80px]'>
          {itemList.map((item, index) => (
            <img
              key={index}
              src={item.url}
              alt='이미지'
              className='object-cover w-full'
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default PGIntroMobile
