import React from 'react'

const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL

const BillHeader = () => {
  const logo = `${s3URL}/asset/images/logo/TKW_LOGO.png`
  return (
    <div className='header-logo'>
      <a href='/'>
        <img
          src={logo}
          alt='logo'
          className='w-[167px] h-[33px]'
        />
      </a>
    </div>
  )
}

export default BillHeader
