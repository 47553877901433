import React from 'react'
import Tooltip from '../TOOLTIP/Tooltip'

const labelStyle = {
  // fontWeight: 'bold',
  textAlign: 'left',
  wordBreak: 'keep-all'
}

const reqStyle = {
  color: '#EC0000',
  fontWeight: 'normal'
}

const AlignLabel = ({ label, req, minWidth, width, fontSize, bold, className, tooltip }) => {
  return(
    <>
      <span style={{...labelStyle, minWidth: minWidth || '150px', width: width || '150px', fontSize: fontSize || '16px', fontWeight: bold || '700'}} className={className}>
        {label}
        {req && 
          <span style={reqStyle}> *</span>
        }
        { tooltip && 
          <Tooltip content={tooltip} placement='right-start' labelTooltip/>
        }
      </span>
    </>
  )
}

export default AlignLabel