import React from 'react'

const bgStyle = {
  width: '100%',
  minWidth: '1920px',
  height: '350px',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: '1'
}

const DashboardBackground = ({ black }) => {
  return (
    <div style={{ backgroundColor: black ? '#656565' : '#89A3FF', ...bgStyle}} />
  )
}

export default DashboardBackground