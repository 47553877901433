import { InputAdornment, TextField } from '@mui/material'
import React from 'react'
import '../../../style/custom.css'
import { useDebug } from '../../../hooks/basic/useDebug'

const InputStyle = {
  // label
  '& .MuiInputLabel-root': {
    left: 'initial',
    top: '-5px',
    color: '#737373',
    backgroundColor: '#fff',
    '&.Mui-focused': {
      top: '0',
      color: '#000',
    },
  },
  '& .Mui-focused': {
    color: '#000', // label font color
  },
  '& .MuiInputLabel-shrink': {
    top: '0',
  },

  // input
  '& .MuiOutlinedInput-input': {
    border: '1px solid #EEEEEE',
    backgroundColor: '#fff',
    borderRadius: '5px',
    // disabled
    '&.Mui-disabled': {
      backgroundColor: '#F7F7F7',
      color: '#000',
    },
  },

  // error일 때
  '& .MuiOutlinedInput-root.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
    borderColor: '#000', // focus border color when not in error state
  },
  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: 'red',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: 'red',
  },

  // multline일 때
  '& .MuiInputBase-inputMultiline': {
    border: '0 none',
    padding: '0',
  },
}

const adornmentStyle = {
  position: 'absolute',
  right: '15px',
}

const Input = React.forwardRef(
  ({ minWidth, width, padding, pr, adornment, sx, inputProps, hidden, onChange, onKeyDown, ...props }, ref) => {
    return (
      <TextField
        autoComplete='off'
        inputRef={ref}
        variant='outlined'
        sx={{
          minWidth: minWidth || 'auto',
          width: width || '100%',
          '& .MuiOutlinedInput-input': {
            ...InputStyle['& .MuiOutlinedInput-input'],
            padding: padding || '11.5px 15px',
            paddingRight: adornment ? '45px' : '15px',
          },
          display: hidden && 'none',
          ...sx,
        }}
        onChange={onChange}
        onKeyDown={onKeyDown}
        inputProps={{ ...inputProps, onWheel: (e) => e.target.blur(), autoComplete: 'off' }}
        InputProps={{
          autoComplete: 'off',
          endAdornment: adornment && (
            <InputAdornment
              position='end'
              sx={adornmentStyle}
            >
              {adornment}
            </InputAdornment>
          ),
        }}
        {...props}
      />
    )
  },
)

Input.displayName = 'Input'
export default Input
