import React from 'react'
import Typography from '../../../../../UI/ATOM/TEXT/HOME/Typography'
import { FormattedMessage } from 'react-intl'

const FeeTable = () => {
  return (
    <div className='px-[50px] pt-6 pb-[150px] mb-[50px] bg-white'>
      <div>
        <Typography className='text-[#000000] text-[18px]'>
          <FormattedMessage id='- 결제수수료' />
        </Typography>
      </div>
      <div className='mt-4'>
        <table className='w-[60%] border border-solid border-collapse text-center'>
          <thead className='bg-[#F2F2F2] h-[57px]'>
            <tr>
              <th className='border border-solid border-[#C6C6C6]'></th>
              <th
                className='border border-solid border-[#C6C6C6] text-[18px] font-thin'
                colSpan='5'
              >
                결제수수료
              </th>
              <th className='border border-solid border-[#C6C6C6] text-[18px] font-thin'>비고</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className='border border-solid border-[#C6C6C6] bg-[#F2F2F2] h-[155px]'
                rowSpan='2'
              >
                신용카드
              </td>
              <td className='border border-solid border-[#C6C6C6] h-[55px]'>영세</td>
              <td className='border border-solid border-[#C6C6C6] h-[55px]'>중소1</td>
              <td className='border border-solid border-[#C6C6C6] h-[55px]'>중소2</td>
              <td className='border border-solid border-[#C6C6C6] h-[55px]'>중소3</td>
              <td className='border border-solid border-[#C6C6C6] h-[55px]'>일반</td>
              <td
                className='border border-solid border-[#C6C6C6]'
                rowSpan='2'
              >
                PG 결제창
                <br />
                NFC 결제
                <br />
                OCR 결제
                <br />
                Keyin 결제
              </td>
            </tr>
            <tr>
              <td className='border border-solid border-[#C6C6C6]'>1.8 %</td>
              <td className='border border-solid border-[#C6C6C6]'>2.3 %</td>
              <td className='border border-solid border-[#C6C6C6]'>2.45 %</td>
              <td className='border border-solid border-[#C6C6C6]'>2.7 %</td>
              <td className='border border-solid border-[#C6C6C6]'>3.2 %</td>
            </tr>
            <tr>
              <td className='border border-solid border-[#C6C6C6] bg-[#F2F2F2] h-[80px]'>가상계좌</td>
              <td
                className='border border-solid border-[#C6C6C6] text-start px-[20px]'
                colSpan='5'
              >
                건당 300 원
              </td>
              <td className='border border-solid border-[#C6C6C6]'></td>
            </tr>
            <tr>
              <td className='border border-solid border-[#C6C6C6] bg-[#F2F2F2] h-[80px]'>계좌이체</td>
              <td
                className='border border-solid border-[#C6C6C6] text-start px-[20px]'
                colSpan='5'
              >
                1.7 %
              </td>
              <td className='border border-solid border-[#C6C6C6]'></td>
            </tr>
            <tr>
              <td className='border border-solid border-[#C6C6C6] bg-[#F2F2F2] h-[158px]'>간편결제</td>
              <td
                className='border border-solid border-[#C6C6C6] text-start px-[20px]'
                colSpan='5'
              >
                3.3 %
              </td>
              <td className='border border-solid border-[#C6C6C6]'>
                카카오페이
                <br />
                페이코
                <br />
                네이버페이
                <br />
                삼성페이
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default FeeTable
