import { Navigate, Outlet } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import dojangIdAtom from '../../../recoil/atom/dojangIdAtom'
import dojangInfoAtom from '../../../recoil/atom/dojangInfoAtom'

const DojangSelectRoutes = () => {
  const dojangId = useRecoilValue(dojangIdAtom)
  const dojangInfo = useRecoilValue(dojangInfoAtom)

  return !dojangId || !dojangInfo || dojangId !== dojangInfo?.dojang_id ? <Navigate to='/dojang/select' /> : <Outlet />
}

export default DojangSelectRoutes
