import React from 'react'
import { FormattedMessage } from 'react-intl'
import FlexColWrap from '../../../../../UI/LAYOUT/WRAP/FlexColWrap'
import FlexWrap from '../../../../../UI/LAYOUT/WRAP/FlexWrap'
import ClearIcon from '@mui/icons-material/Clear'
import Typography from '../../../../../UI/ATOM/TEXT/HOME/Typography'
import { useNavigate, useParams } from 'react-router-dom'
import BillFooter from '../../CHECK/Components/BillFooter'

const Failure = ({ resultCode, message }) => {
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const bill_id = queryParams.get('bill_id')

  const handleBillCheck = () => {
    navigate(`/bill/${bill_id}`)
  }

  return (
    <FlexColWrap>
      <FlexWrap className='w-full justify-center pt-[15px] pb-[12px] text-[#DF0C0C]'>
        <ClearIcon />
        <FormattedMessage id='결제실패' />
      </FlexWrap>
      <div className='flex flex-col py-[19px] text-[#757575]'>
        <Typography className='text-sm'>
          <FormattedMessage id='결제요청에 실패하였습니다.' />
        </Typography>
        <Typography className='text-sm'>
          <FormattedMessage id='사유' />
          <span> {message}</span>
        </Typography>
        <Typography className='text-sm'>
          <FormattedMessage id='에러코드' />
          <span> {resultCode}</span>
        </Typography>
      </div>
      <div className='flex justify-center pb-[350px]'>
        <Typography className='text-sm cursor-pointer underline text-[#528EFF]'>
          <span onClick={handleBillCheck}>
            <FormattedMessage id='청구서로 돌아가기' />
          </span>
        </Typography>
      </div>
      <div>
        <BillFooter />
      </div>
    </FlexColWrap>
  )
}

export default Failure
