import { FormattedMessage } from "react-intl";

export const requiredRule = {
  required: {
    value: true,
    message: <FormattedMessage id='rule.required' />,
  },
}

export function minLengthRule (v) {
  return {
    minLength: {
      value: v,
      message: <FormattedMessage id='rule.minLength' values={{ n: v }} />
    }
  }
}
