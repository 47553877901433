import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import userInfoAtom from '../../../recoil/atom/login/userInfoAtom'

const LoginRoutes = ({ userRole }) => {
  const { user_role } = useRecoilValue(userInfoAtom)

  return !userRole.includes(user_role) ? (
    <Navigate to="/account/error/access" />
  ) : (
    <Outlet />
  )
}

export default LoginRoutes
