import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import FlexColWrap from '../../../../UI/LAYOUT/WRAP/FlexColWrap'
import BillFooter from './Components/BillFooter'
import { BillInquiry, BillStatusMessage } from './Components/BillStatusManagement'
import BillHeader from './Components/BillHeader'
import { getBillCheck, getBillTokenCheck } from '../../../../api/Payment/payment'
import { useQuery } from '@tanstack/react-query'

const BillStatus = () => {
  const { bill_id } = useParams()
  const { data } = useQuery(['getBillCheck', bill_id], () => getBillCheck(bill_id), {
    enabled: !!bill_id,
  })

  const [status, setStatus] = useState(data.STATUS_CD)

  // 청구서 임시 status_code
  // 1 : 결제대기
  // 2 : 결제완료
  // 3 : 결제취소
  // 4 : 만료
  // 5 : 삭제
  // 나머지는 존재하지 않는 형태

  const renderContentByStatus = () => {
    switch (status) {
      case 1:
        return (
          <BillInquiry
            data={data}
            logo={data.LOGO_IMG}
            // tokenData={tokenData}
          />
        ) // 결제 대기
      case 2:
      case 3:
      case 4:
      case 5:
      default:
        return <BillStatusMessage status_code={status} />
    }
  }

  return (
    <div className='flex justify-center min-h-screen'>
      <div className='w-full py-[25px] px-[20px] text-center h-full max-w-[575px]'>
        <FlexColWrap>
          <BillHeader />
          {renderContentByStatus()}
        </FlexColWrap>
      </div>
    </div>
  )
}

export default BillStatus
