import React from 'react'
import { useRecoilValue } from 'recoil'
import dojangIdAtom from '../../../recoil/atom/dojangIdAtom'
import { useQuery } from '@tanstack/react-query'
import { checkPGState } from '../../../api/Payment/payment'
import { Navigate, Outlet } from 'react-router-dom'
import ServicePgSign from '../../../PAGES/USERDOJANG/SERVICE/PG/ServicePgSign'

const CheckJoinedPG = () => {
  const dojang_id = useRecoilValue(dojangIdAtom)
  const { data } = useQuery(['checkPGState', dojang_id], () => checkPGState(dojang_id), {
    enabled: !!dojang_id,
  })

  const pgState = data?.ACCESSION

  return pgState !== 2 ? <ServicePgSign /> : <Outlet />
}

export default CheckJoinedPG
