import React, { useMemo, useState } from 'react'
import Select from '../../../../ATOM/SELECT/Select'
import LoadingModal from '../../../../MOLECULES/LOADING/LoadingModal'

const SelectNation = (props) => {
  // localStorage.removeItem('locale')
  const [loading, setLoading] = useState(false)

  const currLang = localStorage.getItem('locale')

  if (!currLang) {
    localStorage.setItem('locale', 'ko')
  }

  const handleLang = (lang) => {
    // console.log(lang)
    setLoading(true)
    try {
      localStorage.setItem('locale', lang)
      setTimeout(() => window.location.reload(), 500)
    } catch {
      setLoading(false)
    }
  }

  const nationList = useMemo(
    () => [
      {
        id: 'ko',
        title: (
          <>
            <img
              className='mr-1 align-middle'
              src='https://flagcdn.com/w20/kr.png'
              srcSet={`https://flagcdn.com/w40/kr.png 2x`}
              alt=''
            />
            한국어
          </>
        ),
      },
      {
        id: 'en',
        title: (
          <>
            <img
              className='mr-1 align-middle'
              src='https://flagcdn.com/w20/us.png'
              srcSet={`https://flagcdn.com/w40/us.png 2x`}
              alt=''
            />
            English
          </>
        ),
      },
      // { id: 'zh', title: <FormattedMessage id='중국어' /> },
      // { id: 'es', title: <FormattedMessage id='스페인어' /> },
      // { id: 'pt', title: <FormattedMessage id='포르투갈어' /> },
    ],
    [],
  )

  return (
    <>
      <Select
        item={nationList}
        value={currLang ?? 'ko'}
        onChange={(e) => handleLang(e.target.value)}
        width='123px'
        padding='9.5px 15px'
        {...props}
      />
      {/* <div className='flex'>
        <img
          className="mr-[10px] cursor-pointer opacity-80"
          src={`${s3URL}/asset/images/home/image/nation/KR.png`}
          onClick={() => handleLang('ko')}
        />
        <img
          className="mr-[10px] cursor-pointer opacity-80"
          src={`${s3URL}/asset/images/home/image/nation/EN.png`}
          onClick={() => handleLang('en')}
        />
        <img
          className="mr-[10px] cursor-pointer opacity-80"
          src={`${s3URL}/asset/images/home/image/nation/CH.png`}
          onClick={() => handleLang('zh')}
          />
        <img
          className="mr-[10px] cursor-pointer opacity-80"
          src={`${s3URL}/asset/images/home/image/nation/SP.png`}
          onClick={() => handleLang('es')}
          />
        <img
          className="cursor-pointer opacity-80"
          src={`${s3URL}/asset/images/home/image/nation/PT.png`}
          onClick={() => handleLang('pt')}
        />
      </div> */}
      {loading && <LoadingModal />}
    </>
  )
}
export default SelectNation
