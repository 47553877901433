import React from 'react'
import { useResize } from '../../../../hooks/window/useResize'

const wrapWidthSize = (size) => {
  switch (size) {
    case 'lg':
      // return 'w-[1280px]'
      return 'w-[1280px] m-auto'
    case 'full':
      return 'w-[100%] m-auto'
    default:
      return ''
  }
}

const Wrap = ({ size, py, px, className, children }) => {
  const deviceStyle = useResize({
    lg: wrapWidthSize('lg'),
    md: wrapWidthSize('full'),
    sm: wrapWidthSize('full'),
  })

  const paddingStyle = useResize({
    lg: `${py ? `py-[${py}]` : 'py-[40px]'} ${px ? `px-[${px}]` : 'px-[100px]'}`,
    md: `${py ? `py-[${py}]` : 'py-[40px]'}`,
    sm: `${py ? `py-[${py}]` : 'py-[40px]'}`,
    // md: 'py-[40px]',
    // sm: 'py-[40px]',
  })

  return <div className={`${deviceStyle} ${paddingStyle} ${className ?? ''}`}>{children}</div>
}

export default Wrap
