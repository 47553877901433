import aws from 'aws-sdk'
import heic2any from 'heic2any'

const accessKeyId = process.env.REACT_APP_AWS_S3_ACCESSKEY_ID
const secretAccessKey = process.env.REACT_APP_AWS_S3_SECRET_ACCESSKEY
const region = process.env.REACT_APP_AWS_S3_FILE_BUCKET_REGION
const bucketName = process.env.REACT_APP_AWS_S3_FILE_BUCKET_NAME

export const s3URLToFile = async (url, name) => {
  if (!url?.includes('blob:')) {
    aws.config.update({
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      region: region,
    })
    const s3 = new aws.S3()

    const bucket = bucketName
    const key = url
    const signedUrl = await s3.getSignedUrlPromise('getObject', { Bucket: bucket, Key: key })

    const blob = await fetch(signedUrl).then((res) => res.blob())
    // console.log('blob: ', blob)

    // const isHeic =
    //   blob?.type === 'image/heic' ||
    //   blob?.type === 'image/heif' ||
    //   url?.toLowerCase().endsWith('.heic') ||
    //   url?.toLowerCase().endsWith('.heif')

    // if (isHeic) {
    //   try {
    //     const jpegBlob = await heic2any({
    //       blob: blob,
    //       toType: 'image/jpeg',
    //     })

    //     return jpegBlob
    //   } catch (e) {
    //     alert('이미지 파일을 불러오는데 실패하였습니다.')
    //     console.error('HEIC conversion error:', e)
    //   }
    // } else {
    const file = new File([blob], `${name}.png`, { type: blob.type })
    return file
    // }
  }

  if (url?.includes('blob:')) {
    const response = await fetch(url)
    const blob = await response.blob()

    // const isHeic =
    //   blob?.type === 'image/heic' ||
    //   blob?.type === 'image/heif' ||
    //   url?.toLowerCase().endsWith('.heic') ||
    //   url?.toLowerCase().endsWith('.heif')

    // if (isHeic) {
    //   try {
    //     const jpegBlob = await heic2any({
    //       blob: blob,
    //       toType: 'image/jpeg',
    //     })

    //     return jpegBlob
    //   } catch (e) {
    //     alert('이미지 파일을 불러오는데 실패하였습니다.')
    //     console.error('HEIC conversion error:', e)
    //   }
    // } else {
    const file = new File([blob], `${name}.png`, { type: blob.type })
    return file
    // }
  }
}

export const s3FileDownload = async (urlKey, fileName) => {
  aws.config.update({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    region: region,
  })
  const s3 = new aws.S3()

  const bucket = bucketName
  const key = urlKey
  const signedUrl = await s3.getSignedUrlPromise('getObject', { Bucket: bucket, Key: key })

  const blob = await fetch(signedUrl).then((res) => res.blob())

  const file = new File([blob], `${name}.png`, { type: blob.type })

  const url = URL.createObjectURL(file)

  let a = document.createElement('a')
  a.href = url
  a.download = fileName ?? decodeURI(urlKey?.split('/')?.at(-1))

  document.body.appendChild(a)

  a.click()

  document.body.removeChild(a)
}

export const heicToJpg = async (url) => {
  if (!url?.includes('blob:')) {
    aws.config.update({
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      region: region,
    })
    const s3 = new aws.S3()

    const bucket = bucketName
    const key = url
    const signedUrl = await s3.getSignedUrlPromise('getObject', { Bucket: bucket, Key: key })

    const blob = await fetch(signedUrl).then((res) => res?.blob())

    // const blob = await response?.blob()

    // console.log('blob: ', blob)

    const isHeic =
      blob?.type === 'image/heic' ||
      blob?.type === 'image/heif' ||
      url?.toLowerCase().endsWith('.heic') ||
      url?.toLowerCase().endsWith('.heif')

    if (isHeic) {
      try {
        const jpegBlob = await heic2any({
          blob: blob,
          toType: 'image/jpeg',
          // quality: 0.8,
        })

        // console.log('jpegBlob', jpegBlob)
        const convertUrl = URL.createObjectURL(jpegBlob)
        // console.log('convertUrl', convertUrl)
        return convertUrl
      } catch (e) {
        alert('이미지 파일을 불러오는데 실패하였습니다.')
        console.error('HEIC conversion error:', e)
      }
    } else return url
  }
}
