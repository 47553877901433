import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'dojangId',
  storage: sessionStorage,
});

const dojangIdAtom = atom({
    key: 'dojangId',
    default: '',
    effects_UNSTABLE: [persistAtom],
});

export default dojangIdAtom;
