import React, { useState } from 'react'

export const useSnack = () => {
  const [open, setOpen] = useState(false)
  const openFn = () => {
    setOpen(true)
    setTimeout(() => setOpen(false), 1500)
  }
  const closeFn = () => {
    setOpen(false)
  }
  return [
    {
      open,
      onClose: () => closeFn(),
      message: '체험판에서는 제공하지 않는 기능입니다.',
    },
    openFn,
  ]
}
