import React from 'react'
import PgSignComponent from './Components/PgSignComponent'
import ApiErrorBoundary from '../../../../UI/TEMPLATES/ERROR/ErrorBoundary/ApiErrorBoundary'
import ContainerNoPadding from '../../../../UI/LAYOUT/CONTAINER/ContainerNoPadding'

const ServicePgSign = () => {
  return (
    <ContainerNoPadding>
      <ApiErrorBoundary>
        <PgSignComponent />
      </ApiErrorBoundary>
    </ContainerNoPadding>
  )
}

export default ServicePgSign
