import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import userInfoAtom from '../../../../../../recoil/atom/login/userInfoAtom'
import { useRecoilValue } from 'recoil'
import dojangInfoAtom from '../../../../../../recoil/atom/dojangInfoAtom'
import { S3URL } from '../../../../../../UTILS/URL/url'
import { IconButton } from '@mui/material'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import TextHoverPopover from '../../../../../ATOM/POPOVER/TextHoverPopover'
import Typography from '../../../../../ATOM/TEXT/Typography'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import Popper from '@mui/material/Popper'

const SideBarDojangInfo = () => {
  const nav = useNavigate()

  // intl
  const intl = useIntl()

  // hooks
  const { user_role } = useRecoilValue(userInfoAtom)
  const dojangInfo = useRecoilValue(dojangInfoAtom)

  // popover
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div className='min-h-[118px] px-2 py-3 border-[1px] border-solid border-[var(--color-border-grey-400)] border-l-0 bg-[#fff]'>
      {/* 도장 셀렉트박스 */}
      <div className='flex justify-center items-center px-3 relative'>
        {/* <TextHoverPopover
          text={
            <img
              src={S3URL + dojangInfo?.logo_img}
              alt=''
              className='w-[100px] h-[60px] object-contain drop-shadow'
            />
          }
          popover={<div className=''>{dojangInfo.dojang_name}</div>}
        /> */}

        <div className='flex flex-col items-center gap-2'>
          <img
            src={S3URL + dojangInfo?.logo_img}
            alt='logo'
            className='w-[100px] h-[60px] object-contain drop-shadow'
          />
          <Typography className='w-[180px] text-center truncate font-bold'>{dojangInfo.dojang_name}</Typography>
        </div>

        <div className='flex absolute top-[-3px] right-[5px] gap-[2px]'>
          {/* ICON BUTTON */}
          <div>
            <IconButton
              onClick={handleClick}
              style={{
                width: '25px',
                height: '25px',
              }}
            >
              <MoreVertIcon className='text-[20px] text-[#494949]' />
            </IconButton>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              // placement='bottom-start'
              className='z-[9999]'
              disablePortal
            >
              <Box
                sx={{
                  border: '1px solid var(--color-border-grey-400)',
                  borderRadius: '4px',
                  padding: '5px',
                  bgcolor: '#fff',
                  cursor: 'pointer',
                  minWidth: '110px',
                }}
                onClick={() => nav('/dojang/select')}
              >
                <Typography
                  variant='h6'
                  className='text-center'
                >
                  <FormattedMessage id='등록된 도장 보기' />
                </Typography>
              </Box>
            </Popover>
          </div>
          {/* <TextHoverPopover
            text={
              <IconButton
                style={{
                  width: '20px',
                  height: '20px',
                }}
                onClick={() => nav('/dojang/select')}
              >
                <MoreVertIcon className='text-[20px] text-[#494949]' />
              </IconButton>
            }
            popover={
              <Typography variant='h6'>
                <FormattedMessage id='등록된 도장 보기' />
              </Typography>
            }
          /> */}

          {/* 2차 수정했던거 */}
          {/* <TextHoverPopover
            text={
              <IconButton
                style={{
                  borderRadius: '0px',
                  width: '16px',
                  height: '16px',
                  border: '1px solid #c8c8c8',
                  background: '#eeeeee',
                }}
                onClick={() => nav('/dojang/select')}
              >
                <LowPriorityIcon className='text-[14px] text-[#494949]' />
              </IconButton>
            }
            popover={
              <Typography variant='h6'>
                <FormattedMessage id='등록된 도장 보기' />
              </Typography>
            }
          /> */}

          {/* 옛날거 */}
          {/* <TextHoverPopover
            text={
              <IconButton
                style={{
                  borderRadius: '0px',
                  width: '20px',
                  height: '20px',
                  border: '1px solid #c8c8c8',
                }}
                onClick={() => {
                  if (user_role === 'KW') {
                    nav('/dojang/regist')
                  }
                  if (user_role === 'SA') {
                    alert(intl.formatMessage({ id: '권한이 없습니다.' }))
                  }
                }}
              >
                <PlaylistAddIcon className='text-[16px] text-[var(--color-font-grey)]' />
              </IconButton>
            }
            popover={
              <Typography variant='h6'>
                <FormattedMessage id='새 도장 등록하기' />
              </Typography>
            }
          /> */}
        </div>
      </div>
    </div>
  )
}

export default SideBarDojangInfo
