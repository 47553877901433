import React, { useState } from 'react'

export const useModal = (defaultState) => {
  const [value, setValue] = useState(defaultState ?? false)
  const openFn = () => {
    setValue(true)
  }
  const closeFn = () => {
    setValue(false)
  }
  return [value, openFn, closeFn]
}
