import axios from 'axios'
import { s3URLToFile } from '../UTILS/AWS/FILE/s3URLToFile'
import authApi from './authApi'
import { addCountryCode } from './contryCode'

// 유저 회원가입(등록)
export const registerFn = async (body) => {
  const res = await authApi.post('user/register', body)
  return res.data
}

// 유저 로그인
export const loginFn = async (body) => {
  const res = await authApi.post('user/login', body)
  return res.data
}

// 탈퇴
export const deleteUser = async () => {
  const res = await authApi.delete('user/secession')
  return res.data
}

// 비밀번호 변경 (수정 changemyinfo --> changepassword)
export const changePW = async (body) => {
  const res = await authApi.post('user/changepassword', body)
  return res.data
}

// 이메일 변경 (수정 changemyinfo --> changeemail)
export const changeEmail = async (body) => {
  const res = await authApi.post('user/changeemail', body)
  return res.data
}

// 계정 -> role에 해당하는 정보 조회 (수정 -> is_linked로 대체)
export const getMyInfo = async (body) => {
  const fullBody = addCountryCode(body)
  const res = await authApi.post('user/finduser/', fullBody)
  return res.data
}

// 계정이 연결되어 있는지 체크
export const getLinkedInfo = async () => {
  const res = await authApi.get('user/is_linked')
  return res.data
}

// 계정이 연결되어 있는지 체크 (파일 변환)
export const getLinkedInfoforUpdate = async () => {
  const { data } = await authApi.get('user/is_linked')
  if (data?.PROFILE_IMG) {
    const profilePromise = await s3URLToFile(data?.PROFILE_IMG, 'profile_img')
    const [profileFile] = await Promise.all([profilePromise])

    return {
      ...data,
      img: profileFile,
    }
  } else {
    return data
  }
}

// 핸드폰 번호 변경 (수정)
export const changePhone = async (body) => {
  const res = await authApi.post('user/changephonenumber', body)
  return res.data
}

// 아이디 중복 체크 (수정)
export const checkIdFn = async (body) => {
  const res = await authApi.post('user/chk_username', body)
  return res.data
}

// 휴대전화 번호 중복 체크
export const checkPhone = async (body) => {
  const res = await authApi.post('user/is_phonenumber_duplicate', body)
  return res.data
}

// 아이디 찾기

// 이메일로 인증 번호 전송 (생성)
export const sendEmailforUsername = async (body) => {
  const res = await authApi.post('util/sendemail_email', body)
  return res.data
}

// 이메일 & 인증 번호로 아이디 찾기
export const findUserNameByEmail = async (body) => {
  const res = await authApi.post('util/findusername_email', body)
  return res.data
}

// 회원 가입용 휴대전화 번호로 인증 번호 전송 & 휴대 전화번호 변경 인증번호 전송 (수정 sendsms_username ---> sendsms_phone)
export const sendSMSforRegist = async (body) => {
  const res = await authApi.post('util/sendsms_phone', body)
  return res.data
}

// 아이디 찾기용 휴대전화 번호로 인증 번호 전송
export const sendSMSforUsername = async (body) => {
  const res = await authApi.post('util/sendsms_phone2', body)
  return res.data
}

// 휴대전화 번호 & 인증 번호로 아이디 찾기
export const findUserNameByPhone = async (body) => {
  const res = await authApi.post('util/findusername_phone', body)
  return res.data
}

// 유저 비밀번호 재설정
// ID 인증 ->  휴대전화으로 인증번호 전송 (수정 sendsms_password ---> sendsms_username)
export const sendSMSforPW = async (body) => {
  const res = await authApi.post('util/sendsms_username', body)
  return res.data
}
// ID를 통해 이메일로 인증번호 전송?????????
export const sendEmailforPW = async (body) => {
  const res = await authApi.post('util/sendemail_username', body)
  return res.data
}
// 비밀번호 재설정
export const resetUserPW = async (body) => {
  const res = await authApi.post('util/reset_password', body)
  return res.data
}

// 인증번호 확인
export const authApprove = async (body) => {
  const res = await authApi.post('util/approve', body)
  return res.data
}

// 협회 로그인
// export const AssociationLoginFn = async ([assoName, body]) => {
//   const res = await authApi.post(`user/login?association_name=${assoName}&country_code=KOR`, body);
//   return res.data;
// };

// 협회 로그인 수정
export const AssociationLoginFn = async (body) => {
  const res = await authApi.post('user/login', body)
  return res.data
}
// // 협회 로그인 수정
// export const AssociationLoginFn = async (body) => {
//   const res = await authApi.post(`user/login?association_name=서울&country_code=KOR`, body);
//   return res.data;
// };

// 문의 (수정)
export const contactUs = async (body) => {
  const res = await authApi.post('util/forward_inquiry', body)
  return res.data
}

export const getUserGeolocation = async () => {
  const res = await axios.get('https://geolocation-db.com/json/')
  return res?.data
}
