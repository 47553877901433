import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import BillHeader from '../CHECK/Components/BillHeader'
import FlexColWrap from '../../../../UI/LAYOUT/WRAP/FlexColWrap'
import BillFooter from '../CHECK/Components/BillFooter'
import Success from './Components/Success'
import Failure from './Components/Failure'
import moment from 'moment'

const BillResultComponent = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const paymentStatus = queryParams.get('paymentStatus')
  const cgTid = queryParams.get('cgTid')
  const orderNo = queryParams.get('orderNo')
  const amount = queryParams.get('amount')
  const rawTimestamp = queryParams.get('timestamp')
  const productName = queryParams.get('productName')
  const dojangName = queryParams.get('dojangName')
  const resultCode = queryParams.get('resultCode')
  const message = queryParams.get('message')

  const formatTimestamp = (timestamp) => {
    return moment(timestamp, 'YYYYMMDDHHmmss').format('YYYY.MM.DD HH:mm:ss')
  }

  const timestamp = formatTimestamp(rawTimestamp)

  const renderContentByStatus = () => {
    if (paymentStatus === 'done') {
      return (
        <Success
          orderNo={orderNo}
          timestamp={timestamp}
          amount={amount}
          productName={productName}
          dojangName={dojangName}
        />
      )
    } else if (paymentStatus === 'failure') {
      return (
        <Failure
          resultCode={resultCode}
          message={message}
        />
      )
    } else {
      return <div>결제 상태를 확인할 수 없습니다.</div>
    }
  }

  return (
    <div className='flex justify-center min-h-screen'>
      <div className='w-full py-[25px] px-[20px] text-center h-full max-w-[575px]'>
        <FlexColWrap>
          <BillHeader />
          {renderContentByStatus()}
        </FlexColWrap>
      </div>
    </div>
  )
}

export default BillResultComponent
