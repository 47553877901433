import React from 'react'
import { styled } from '@mui/system'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import PostCode from './PostCode'

const BoxStyle = styled(Box)(({ theme, width, height }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: width,
  height: height,
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 30px',
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  boxShadow: 24,
  overflow: 'auto',
}))

const ModalAddress = React.forwardRef(({ open, onClose, setValue, width, height, controlled, upper }, ref) => {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
      >
        <BoxStyle
          width={width || 700}
          height={height || 520}
        >
          <PostCode
            setValue={setValue}
            onClose={onClose}
            controlled={controlled}
            upper={upper}
          />
        </BoxStyle>
      </Modal>
    </>
  )
})

ModalAddress.displayName = 'ModalAddress'
export default ModalAddress
