import React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import Container from '../HOME/CONTAINER/Container'
import { useResize } from '../../../hooks/window/useResize'
import Logo from '../../../asset/Images/Logo/logo.png'
import FlexWrap from '../WRAP/FlexWrap'
import Typography from '../../ATOM/TEXT/HOME/Typography'
import { FormattedMessage } from 'react-intl'
import SelectNation from '../HOME/HEADER/NATION/SelectNation'

const GuideHeader = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  // MediaQuiry
  const headerWrap = useResize({
    lg: 'w-[1505px] min-w-[1505px] m-auto',
    md: 'w-full',
    sm: 'w-full',
  })

  const logoStyle = useResize({
    lg: 'py-[8.5px]',
    md: 'py-[10px]',
    sm: 'py-[10px] h-[60px]',
  })

  return (
    <Container className='sticky top-0 z-[99] bg-[#fff]'>
      <div className={headerWrap}>
        <FlexWrap className='justify-between items-center'>
          <FlexWrap className='items-center gap-3'>
            <a href='/guide'>
              <img
                src={Logo}
                alt='Taekwonworld'
                className={logoStyle}
              />
            </a>
            <Typography
              variant='h6'
              bold
              className='mt-1'
            >
              <FormattedMessage id='사용자 가이드' />
            </Typography>
          </FlexWrap>
          <FlexWrap className='items-center gap-12'>
            <div>
              {/* <Button
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className='text-[18px] text-[#000]'
              >
                다운로드
              </Button> */}
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <DownloadRoundedIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>모바일 앱 다운로드</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <DownloadRoundedIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>출석 모바일 앱 다운로드</ListItemText>
                </MenuItem>
              </Menu>
            </div>
            <SelectNation />
          </FlexWrap>
        </FlexWrap>
      </div>
    </Container>
  )
}

export default GuideHeader
