import React from 'react'
import { useResize } from '../../../../hooks/window/useResize'

const Container = ({ className, style, children }) => {
  const containerStyle = useResize({
    lg: 'w-full min-w-[1903px] m-auto',
    md: 'px-[40px]',
    sm: 'px-[20px]',
  })

  return (
    <div
      className={`${containerStyle} ${className ?? ''}`}
      style={style}
    >
      {children}
    </div>
  )
}

export default Container
