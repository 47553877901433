import React, { Suspense } from 'react'
import './App.css'
import './style/custom.css'
import { ErrorBoundary } from 'react-error-boundary'
import { IntlProvider } from 'react-intl'
import KR from './UTILS/lang/KR.json'
import EN from './UTILS/lang/EN.json'
import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import Router from './Router'
import LoadingModal from './UI/MOLECULES/LOADING/LoadingModal'
import ErrorFallback from './UI/TEMPLATES/ERROR/ErrorFallback/ErrorFallback'

const locale = localStorage.getItem('locale') ?? 'ko'
const messages = { en: EN, ko: KR }[locale]

function App() {
  const { reset } = useQueryErrorResetBoundary()

  return (
    <IntlProvider
      locale={locale}
      messages={messages}
    >
      <div className='App'>
        <Suspense fallback={<LoadingModal />}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={reset}
          >
            <Router />
          </ErrorBoundary>
        </Suspense>
      </div>
    </IntlProvider>
  )
}

export default App
