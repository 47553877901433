import React, { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import assoInfoAtom from '../../../../recoil/atom/association/assoInfoAtom'
import SideBarMenu from './Components/SideBarMenu'

const SideBarAssociation = () => {
  const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL

  const menu = useMemo(() => {
    return [
      {
        name: 'associtation-home',
        title: '홈',
        path: '/association/home',
        src: `${s3URL}/asset/images/icon/side_menu/home.png`,
      },
      {
        name: 'associtation-levelup',
        title: '승급 이력',
        path: '/association/levelup/list',
        src: `${s3URL}/asset/images/icon/side_menu/levelup.png`,
      },
      {
        name: 'associtation-dojang',
        title: '도장 현황',
        path: '/association/dojang/list',
        src: `${s3URL}/asset/images/icon/side_menu/dojang.png`,
      },
      {
        name: 'associtation-student',
        title: '수련생 현황',
        path: '/',
        src: `${s3URL}/asset/images/icon/side_menu/student.png`,
      },
      {
        name: 'associtation-notice',
        title: '협회 공지',
        path: '/',
        src: `${s3URL}/asset/images/icon/side_menu/notice.png`,
      },
      {
        name: 'associtation-schedule',
        title: '협회 일정',
        path: '/',
        src: `${s3URL}/asset/images/icon/side_menu/attendance.png`,
      },
    ]
  }, [])

  const assoInfo = useRecoilValue(assoInfoAtom)

  const image = {
    logo: `${s3URL}/asset/images/logo/TKW_LOGO.png`,
  }

  // useDebug(assoInfo)

  return (
    <div className='side-container'>
      <div className='ASside-profile'>
        <a
          href='/'
          className='flex justify-center'
        >
          <img
            src={image.logo}
            alt='TAEKWON WORLD'
            className='w-[190px] mb-[40px] object-cover'
          />
        </a>
        <div className='ASside-profile-img flex flex-col'>
          <img
            src={`${s3URL}/asset/images/logo/association/${assoInfo?.eng_name}_LOGO.png`}
            alt=''
          />
          <p className='mb-0 font-bold text-[18px] break-keep'>{assoInfo?.full_name}</p>
        </div>
      </div>
      <div className='mt-[20px]'>
        <SideBarMenu menu={menu} />
      </div>
    </div>
  )
}

export default SideBarAssociation
