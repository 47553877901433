export function getRoleText(role) {
  switch (role) {
    case 'KW':
      return '관장님'
    case 'kwanjang':
      return '관장님'
    case 'SA':
      return '사범님'
    case 'sabum':
      return '사범님'
    case 'ST':
      return '수련생'
    case 'US':
      return '수련생'
    case 'PA':
      return '가족'
    default:
      return ' '
  }
}
