import { Typography } from '@mui/material'
import React from 'react'

const AlertText = ({ title, className, children }) => {
  return (
    <Typography
      variant={title ? 'h3' : 'h5'}
      className={`${
        title ? 'mb-[30px] font-bold text-[20px]' : 'leading-[28px] font-normal text-[16px] text-[#000000]'
      } ${className ?? ''}`}
    >
      {children}
    </Typography>
  )
}

export default AlertText
