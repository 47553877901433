import React from 'react'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'

const popoverStyle = {
  '& .MuiPopover-paper': {
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.25)',
  },
}

const TextHoverPopover = ({ width, text, popover, full = false }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  if (full)
    return (
      <span
        className='w-full h-full cursor-pointer flex items-center justify-center'
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Typography className={`w-[${width ?? 'full'}] truncate`}>{text}</Typography>
        <Popover
          id='mouse-over-popover'
          sx={{
            pointerEvents: 'none',
            ...popoverStyle,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          disableScrollLock
        >
          <Box sx={{ p: 1 }}>{popover}</Box>
        </Popover>
      </span>
    )
  return (
    <>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={`w-[${width ?? 'full'}] truncate`}
      >
        {text}
      </Typography>
      <Popover
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none',
          ...popoverStyle,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock
      >
        <Box sx={{ p: 1 }}>{popover}</Box>
      </Popover>
    </>
  )
}

export default TextHoverPopover
